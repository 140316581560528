import * as R from 'ramda'
import { useMemo, useState } from 'react'
import cx from 'classnames'

import { renderContentItems } from 'utilities/contentful'
import {
  CALENDLY_REGEX,
  EMAIL_REGEX,
  GMAIL_REGEX,
  PHONE_REGEX,
  POSTAL_CODE_REGEX,
} from 'constants/regex'

import { parsePhoneNumber, formatPhoneNumber } from 'utilities/strings'
import { parseDate, stringifyDate } from 'utilities/dates'
import WeightPicker from 'components/shared/weight-picker'
import HeightPicker from 'components/shared/height-picker'
import DateInput from 'components/shared/date-input'
import { HelperText } from 'components/design-system/inputs'
import SurveyQuestionTextInput from './text-input'
import SurveyQuestionTextArea from './text-area'
import Select from 'components/shared/select'
import UtcOffsetPicker from 'components/shared/utc-offset-picker'
import FileInput from 'components/shared/file-input'
import NestedMultiSelect from './nested-multi-select'
import MealSelect from './meal-select'
import CuisineSelect from './cuisine-select'
import ExcludedIngredientSelect from './excluded-ingredient-select'
import SingleSelect from './single-select'
import s from './styles.module.css'
import Link from 'next/link'
import urls from '../../../utilities/urls'

const EMPTY_ARRAY = []

const UNIQUE_PHONE_NUMBER_ERROR_MESSAGE = `Your phone number is currently connected to a coach profile. No worries! Please email applicants@trainwithkickoff.com to check on the status of your application.`
const UNIQUE_EMAIL_ERROR_MESSAGE = (
  <span>
    Your email is currently connected to a coach profile. No worries! Please{' '}
    <Link
      href={urls.signIn({
        redirect: urls.coachSignUpSurvey({ questionId: 102 }).href,
      })}>
      click here
    </Link>{' '}
    to continue your application.
  </span>
)

const buildResponseOptions = (question, responsesBySurveyQuestionId) => {
  const includeSelectionsFromQuestionId = R.path(
    ['includeSelectionsFrom', 'fields', 'id'],
    question
  )
  const includeSelections = includeSelectionsFromQuestionId
    ? R.pipe(
        R.propOr([], includeSelectionsFromQuestionId),
        R.map(R.prop('surveyQuestionResponseId')),
        ids => new Set(ids)
      )(responsesBySurveyQuestionId)
    : null

  // Filter out certain responses here.
  //  For now just filter out question about pregnancy for clients
  //  who chose Male as their gender.
  const questionResponses =
    R.propEq('id', 6, question) &&
    R.has(13, responsesBySurveyQuestionId) &&
    R.propEq('responseValue', 'M', R.head(responsesBySurveyQuestionId[13]))
      ? R.reject(R.propEq('id', 18), R.prop('responses', question))
      : R.prop('responses', question)

  return R.pipe(
    R.prop('responses'),
    R.map(response => ({
      value: R.toString(response.id),
      label: response.text,
      subText: response.subText,
      response,
    })),
    R.filter(({ value }) => !includeSelections || includeSelections.has(value))
  )({ ...question, responses: questionResponses })
}

const getModelPathValue = ({ response, options }) => {
  if (!response) return null
  if (response.surveyQuestionResponseId) {
    return response.surveyQuestionResponseId
  }

  return R.pipe(
    R.find(({ response: { value, text } }) => {
      return response.responseValue === (value || text)
    }),
    R.propOr(null, 'value')
  )(options)
}

const buildSelectedOptions = R.pipe(
  R.map(R.prop('surveyQuestionResponseId')),
  R.uniq
)

const QUESTION_TYPE_COMPONENTS = {
  multiSelect: ({
    question,
    options,
    responses,
    respond,
    selectClassName,
    selectOptionClassName,
    isNestedQuestion,
    nestedSurveyQuestionProps,
    forceOldStyle,
    coachProfileVariant,
  }) => {
    const selectedOptions = buildSelectedOptions(responses)

    const onChange = surveyQuestionResponseIds => {
      respond({
        surveyQuestionResponseIds: R.reject(R.isNil, surveyQuestionResponseIds),
      })
    }

    return (
      <NestedMultiSelect
        options={options}
        initialSelectedOptions={selectedOptions || EMPTY_ARRAY}
        onChange={onChange}
        maxSelections={question.maxSelections}
        className={selectClassName}
        optionClassName={selectOptionClassName}
        isNestedQuestion={isNestedQuestion}
        nestedSurveyQuestionProps={nestedSurveyQuestionProps}
        forceOldStyle={forceOldStyle}
        coachProfileVariant={coachProfileVariant}
      />
    )
  },
  agreement: ({
    question,
    options,
    responses,
    respond,
    selectClassName,
    selectOptionClassName,
    isNestedQuestion,
    nestedSurveyQuestionProps,
    forceOldStyle,
    coachProfileVariant,
  }) => {
    const selectedOptions = buildSelectedOptions(responses)

    const onChange = surveyQuestionResponseIds => {
      if (surveyQuestionResponseIds.length === options.length)
        respond({
          surveyQuestionResponseIds: R.reject(
            R.isNil,
            surveyQuestionResponseIds
          ),
        })
      else
        respond({
          surveyQuestionResponseIds: [],
        })
    }

    return (
      <NestedMultiSelect
        options={options}
        initialSelectedOptions={selectedOptions || EMPTY_ARRAY}
        onChange={onChange}
        maxSelections={question.maxSelections}
        className={selectClassName}
        optionClassName={selectOptionClassName}
        isNestedQuestion={isNestedQuestion}
        nestedSurveyQuestionProps={nestedSurveyQuestionProps}
        forceOldStyle={forceOldStyle}
        coachProfileVariant={coachProfileVariant}
        isOnlyCheckboxClickable={true}
        description={question.placeholder}
      />
    )
  },
  select: ({ options, response, respond }) => {
    const onChange = surveyQuestionResponseId => {
      respond({ surveyQuestionResponseId })
    }

    return (
      <SingleSelect
        options={options}
        value={getModelPathValue({ response, options })}
        onChange={onChange}
      />
    )
  },
  dropdown: ({ question, options, response, respond }) => {
    const { placeholder } = question

    const onChange = ev => {
      respond({ surveyQuestionResponseId: parseInt(ev.target.value) })
    }

    return (
      <Select
        options={options}
        value={getModelPathValue({ response, options })}
        isV2={true}
        placeholder={placeholder}
        onChange={onChange}
        includeBlank={true}
      />
    )
  },
  weightPicker: ({ question, response, respond }) => {
    const { placeholder } = question
    const value =
      response && response.responseValue && parseInt(response.responseValue)

    const onBlur = responseValue => {
      respond({ responseValue: R.toString(responseValue) })
    }

    return (
      <WeightPicker value={value} onBlur={onBlur} placeholder={placeholder} />
    )
  },
  heightPicker: ({ question, response, respond }) => {
    const { text } = question
    const value =
      response && response.responseValue && parseInt(response.responseValue)

    const onChange = responseValue => {
      respond({
        responseValue: responseValue ? R.toString(responseValue) : null,
      })
    }

    return <HeightPicker inches={value} onChange={onChange} label={text} />
  },
  date: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value =
      response && response.responseValue && parseDate(response.responseValue)

    const onBlur = responseValue => {
      respond({ responseValue: stringifyDate(responseValue) })
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <DateInput
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
      />
    )
  },
  age: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
    showErrors,
    showInvalid,
    setShowInvalid,
  }) => {
    const value = R.propOr('', 'responseValue', response)

    const onBlur = responseValue => {
      if (responseValue < 0) {
        setShowInvalid(true)
      } else {
        setShowInvalid(false)
      }
      respond({ responseValue })
    }

    return (
      <div>
        <label className={s.ageWrapperB}>
          <div className={s.ageLabel}>Enter your age</div>
          <SurveyQuestionTextInput
            type="number"
            pattern="[0-9]*"
            value={value}
            onBlur={onBlur}
            isV2={true}
            onChangeDetailed={setOnChangeResponse}
            inputClassName={cx(inputClassName, s.ageInput, {
              [s.errorInput]: showInvalid,
            })}
            isRequired={question.isRequired}
            min={0}
          />
        </label>
        {showInvalid && (
          <HelperText
            className={s.ageHelperText}
            error={showInvalid}
            errorText="Enter age as a positive number only."
          />
        )}
        {showErrors && !value && <div className={s.error}>Age is required</div>}
      </div>
    )
  },
  integer: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue: responseValue.toString() })
    }

    const parse = val => {
      const parsedVal = parseInt(R.replace(/\D/g, '', val))

      return isNaN(parsedVal) ? '' : parsedVal
    }

    const handleOnChangeDetailed = params => {
      setOnChangeResponse({ ...params, questionId: question.id })
    }

    return (
      <SurveyQuestionTextInput
        value={value || ''}
        onBlur={onBlur}
        placeholder={placeholder}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
        isRequired={question.isRequired}
        parseValue={parse}
      />
    )
  },
  text: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
    coachProfileVariant,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const handleOnChangeDetailed = params => {
      setOnChangeResponse({ ...params, questionId: question.id })
    }

    return (
      <SurveyQuestionTextInput
        value={value || ''}
        onBlur={onBlur}
        placeholder={placeholder}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
        isRequired={question.isRequired}
        coachProfileVariant={coachProfileVariant}
      />
    )
  },
  longText: ({ question, response, respond, setOnChangeResponse }) => {
    const { placeholder, maxLength } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const handleOnChangeDetailed = params => {
      setOnChangeResponse({ ...params, questionId: question.id })
    }

    return (
      <SurveyQuestionTextArea
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        inputClassName={s.textArea}
        onChangeDetailed={handleOnChangeDetailed}
        maxLength={maxLength || undefined}
        showCharacterCount={true}
      />
    )
  },
  timezone: ({ response, respond }) => {
    const value =
      response && response.responseValue && parseInt(response.responseValue)

    const onChange = responseValue => {
      respond({ responseValue: responseValue.toString() })
    }

    return <UtcOffsetPicker value={value} onChange={onChange} />
  },
  gmail: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
    responseSaveError,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (R.test(GMAIL_REGEX, R.toLower(value || ''))) return null

      return 'Not a valid gmail address'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
        error={responseSaveError ? UNIQUE_EMAIL_ERROR_MESSAGE : false}
      />
    )
  },
  email: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
    coachProfileVariant,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (R.test(EMAIL_REGEX, R.toLower(value || ''))) return null

      return 'Not a valid email address'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
        coachProfileVariant={coachProfileVariant}
      />
    )
  },
  calendlyLink: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (R.test(CALENDLY_REGEX, R.toLower(value || ''))) return null

      return 'Not a valid Calendly link'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
      />
    )
  },
  phoneNumber: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
    coachProfileVariant,
    responseSaveError,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (R.test(PHONE_REGEX, value)) return null

      return 'Not a valid phone number'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value || ''}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        parseValue={parsePhoneNumber}
        formatValue={formatPhoneNumber}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
        coachProfileVariant={coachProfileVariant}
        error={responseSaveError ? UNIQUE_PHONE_NUMBER_ERROR_MESSAGE : false}
      />
    )
  },
  slackId: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (value && value.length > 6) return null

      return 'Not a valid Slack ID'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value || ''}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
      />
    )
  },
  year: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (value && value.length === 4) return null

      return 'Specify a four digit year. ex: 2004'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value || ''}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
      />
    )
  },
  instagramHandle: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (value && value.length > 0) return null

      return 'Not a valid Instagram handle'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value || ''}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
      />
    )
  },
  imageAttachment: ({ question, response, respond }) => {
    const value = response && response.responseValue

    const onChange = responseValue => {
      respond({ responseValue })
    }

    return (
      <FileInput
        fileId={value}
        onChange={onChange}
        uploadWidgetOptions={question.uploadWidgetOptions}
        transformation={question.assetTransformation}
        eagerLoadTransformations={
          question.assetTransformation
            ? [question.assetTransformation]
            : undefined
        }
      />
    )
  },
  zipCode: ({
    question,
    response,
    respond,
    setOnChangeResponse,
    inputClassName,
  }) => {
    const { placeholder } = question
    const value = response && response.responseValue

    const onBlur = responseValue => {
      respond({ responseValue })
    }

    const getError = value => {
      if (R.isNil(value) || R.isEmpty(value)) return null
      if (R.test(POSTAL_CODE_REGEX, value)) return null

      return 'Not a valid US / Canada postal code'
    }

    const handleOnChangeDetailed = onChangeResponse => {
      setOnChangeResponse(onChangeResponse)
    }

    return (
      <SurveyQuestionTextInput
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        getError={getError}
        isV2={true}
        onChangeDetailed={handleOnChangeDetailed}
        inputClassName={inputClassName}
        maxLength="10"
      />
    )
  },
  freeFormSkip: () => {
    return null
  },
  MealSelect,
  CuisineSelect,
  ExcludedIngredientSelect,
  __default: ({ question }) => question.type,
}

const getQuestionComponent = ({ type, component }) => {
  if (type === 'custom' && component) {
    return QUESTION_TYPE_COMPONENTS[component] || R.always(component)
  }

  return QUESTION_TYPE_COMPONENTS[type] || QUESTION_TYPE_COMPONENTS.__default
}

const SurveyQuestion = ({
  respond,
  question,
  responseBySurveyQuestionId,
  responsesBySurveyQuestionId,
  setOnChangeResponse,
  inputClassName,
  selectClassName,
  selectOptionClassName,
  goToNextQuestion,
  renderHeader,
  isSubQuestion = false,
  isNestedQuestion = false,
  hideHeader = false,
  hideNote = false,
  showErrors = false,
  forceOldStyle,
  coachProfileVariant,
  responseSaveError,
}) => {
  const { text, note, richTextNote } = question
  const QuestionComponent = getQuestionComponent(question)

  const [showInvalid, setShowInvalid] = useState(false)

  const options = useMemo(() => {
    return buildResponseOptions(question, responsesBySurveyQuestionId)
  }, [question, responsesBySurveyQuestionId])

  const responses = responsesBySurveyQuestionId[question.id] || []
  const response = responseBySurveyQuestionId[question.id]

  const respondWithParams = params => {
    respond({
      ...params,
      questionId: R.propOr(question.id, 'questionId', params),
    })
  }

  return (
    <div className={s.question}>
      {!isSubQuestion && !isNestedQuestion && !hideHeader && !renderHeader && (
        <h2 className={s.header}>{text}</h2>
      )}
      <QuestionComponent
        question={question}
        options={options}
        respond={respondWithParams}
        response={response}
        responses={responses}
        responseBySurveyQuestionId={responseBySurveyQuestionId}
        responsesBySurveyQuestionId={responsesBySurveyQuestionId}
        setOnChangeResponse={setOnChangeResponse}
        inputClassName={inputClassName}
        selectClassName={selectClassName}
        selectOptionClassName={selectOptionClassName}
        showErrors={showErrors}
        isNestedQuestion={isNestedQuestion}
        nestedSurveyQuestionProps={{
          respond,
          responseBySurveyQuestionId,
          responsesBySurveyQuestionId,
          setOnChangeResponse,
          inputClassName,
          selectClassName,
          selectOptionClassName,
        }}
        forceOldStyle={forceOldStyle}
        coachProfileVariant={coachProfileVariant}
        showInvalid={showInvalid}
        setShowInvalid={setShowInvalid}
        responseSaveError={responseSaveError}
      />
      {note && !hideNote && <div className={s.note}>{note}</div>}
      {richTextNote && !hideNote && (
        <div className={s.note}>{renderContentItems(richTextNote.content)}</div>
      )}
    </div>
  )
}

export default SurveyQuestion
