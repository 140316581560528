import cx from 'classnames'
import { BaseTypography } from 'components/design-system/type-system'
import s from './styles.module.css'

const CoachSpecialties = ({ specialties, bio = false }) => {
  return (
    <div className={cx(s.specialties, { [s.bio]: bio })}>
      <BaseTypography className={cx(s.subTitle, { [s.bio]: bio })} tag="h4">
        Specialties
      </BaseTypography>
      <ul className={cx(s.specialtiesList, { [s.bio]: bio })}>
        {specialties.map((specialty, index) => (
          <li
            key={specialty.name}
            className={cx(s.specialty, { [s.bio]: bio })}>
            {specialty.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CoachSpecialties
