interface RibbonProps {
  className?: string
  style?: React.CSSProperties
}

export default function Ribbon({ className, style }: RibbonProps) {
  return (
    <svg
      viewBox="0 0 56 83"
      fill="none"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2129_80430)">
        <path
          d="M4 1.54455C4 0.69152 4.69152 0 5.54455 0H50.4554C51.3085 0 52 0.691521 52 1.54455V75.1692C52 76.3916 50.6474 77.1295 49.6196 76.468L28.8359 63.0924C28.3268 62.7648 27.6732 62.7648 27.1641 63.0924L6.38043 76.468C5.35255 77.1295 4 76.3916 4 75.1692V1.54455Z"
          fill="url(#paint0_linear_2129_80430)"
        />
        <path
          d="M5.54455 1.54455L50.4554 1.54455L50.4554 75.1692L29.6718 61.7936C28.6536 61.1383 27.3464 61.1383 26.3282 61.7936L5.54455 75.1692L5.54455 1.54455Z"
          stroke="#0F172A"
          strokeWidth="3.08911"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2129_80430"
          x="0.910891"
          y="0"
          width="54.1782"
          height="82.895"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.08911" />
          <feGaussianBlur stdDeviation="1.54455" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2129_80430"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2129_80430"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2129_80430"
          x1="28"
          y1="0"
          x2="28"
          y2="78"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBBD23" />
          <stop offset="1" stopColor="#F59F0A" />
        </linearGradient>
      </defs>
    </svg>
  )
}
