import { useState } from 'react'
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'

import cx from 'classnames'
import s from './styles.module.css'

const SurveyQuestionTextArea = ({
  value: initialValue,
  error: propError,
  label,
  inputClassName,
  wrapperClassName,
  errorClassName,
  icon,
  placeholder = '',
  onChange = () => {},
  onChangeDetailed = () => {},
  onBlur = () => {},
  getError = () => null,
  isInvalid = false,
  isRequired = false,
  showCharacterCount = false,
  ...inputProps
}) => {
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState(
    initialValue ? getError(initialValue) : null
  )

  const handleChange = ev => {
    const newValue = ev.target.value

    setError(null)
    setValue(newValue)

    onChange(newValue)

    const error = getError(newValue)
    setError(error)
    onChangeDetailed({
      value: newValue,
      error,
      isValid: !error && !isInvalid && (!isRequired || !!newValue),
    })
  }

  const handleBlur = () => {
    const error = getError(value)
    if (error) return setError(error)

    onBlur(value)
  }

  return (
    <div className={cx(s.wrapper, wrapperClassName)}>
      {label && <div className={s.label}>{label}</div>}
      {/* Grammarly will only be active if the page is wrapped in GrammarlyProvider */}
      <GrammarlyEditorPlugin>
        <div className={s.inputWrapper}>
          <textarea
            {...inputProps}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            className={cx(
              s.input,
              { [s.inputInvalid]: isInvalid },
              inputClassName
            )}
            onClick={e => {
              e.stopPropagation()
            }}
          />
          <div className={s.grammarlyButton}>
            <grammarly-button />
          </div>
        </div>
      </GrammarlyEditorPlugin>
      {showCharacterCount && inputProps.maxLength && (
        <div className={s.characterCount}>
          {(value || '').length} / {inputProps.maxLength}
        </div>
      )}
      {(error || propError) && (
        <div className={errorClassName || s.error}>{error || propError}</div>
      )}
    </div>
  )
}

export default SurveyQuestionTextArea
