import * as R from 'ramda'
import useCoachSurveyResponses, {
  COACH_MATCH_PROMOTION_QUESTION_IDS,
} from 'hooks/use-coach-survey-responses'
import { BaseTypography } from 'components/design-system/type-system'
import MusicNote from '../assets/music-note'
import Smiley from '../assets/smiley'
import Section from '../common/section'
import SectionColumn from '../common/section-column'
import SectionImage from '../common/section-image'
import SectionTitle from '../common/section-title'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const Icons = {
  [COACH_MATCH_PROMOTION_QUESTION_IDS.FAVORITE_WORKOUT_MUSIC]: MusicNote,
  [COACH_MATCH_PROMOTION_QUESTION_IDS.MOST_SURPRISED_TO_LEARN_ABOUT_ME]: Smiley,
}

const CoachALittleMoreAboutMeExperiment = ({ coach }: CoachProps) => {
  const coachResponses = useCoachSurveyResponses({
    coach,
    questionIds: [
      COACH_MATCH_PROMOTION_QUESTION_IDS.FAVORITE_WORKOUT_MUSIC,
      COACH_MATCH_PROMOTION_QUESTION_IDS.MOST_SURPRISED_TO_LEARN_ABOUT_ME,
    ],
  })

  return (
    <Section wrap="wrapReverse">
      <SectionColumn>
        <SectionTitle
          subtitle={`About ${R.pathOr('', ['user', 'firstName'], coach)}`}
          title="A Little More About Me"
        />
        <ul>
          {coachResponses.map(qr => {
            const Icon = Icons[qr.id]
            return (
              <li key={qr.id} className={s.li}>
                <div>
                  <Icon />
                </div>
                <div>
                  <BaseTypography
                    className={s.question}
                    tag="div"
                    weight="medium">
                    {qr.question}
                  </BaseTypography>
                  <BaseTypography tag="div" className={s.response}>
                    {qr.response}
                  </BaseTypography>
                </div>
              </li>
            )
          })}
        </ul>
      </SectionColumn>
      <SectionColumn>
        {coach.humanizingImage?.url && (
          <SectionImage
            src={coach.humanizingImage?.url}
            alt="Coach Humanizing"
          />
        )}
      </SectionColumn>
    </Section>
  )
}

export default CoachALittleMoreAboutMeExperiment
