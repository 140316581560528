const Quote = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.502 52.2791C19.931 57.4589 10.4436 56.0893 6.00152 50.6048C0.744114 44.1273 -0.305625 31.8505 8.85347 20.8686C13.3965 15.4214 18.8716 11.2914 25.2731 8.47852L28.2255 13.2336C19.1191 17.6464 12.3128 23.6295 11.3482 33.6605C15.4569 33.6605 18.8658 33.5117 21.9021 34.5981C25.359 35.8351 27.4587 38.1291 28.3204 40.7597C29.6874 44.8785 29.0248 49.0037 25.502 52.2791ZM58.5199 52.2791C52.9489 57.4589 43.4615 56.0893 39.0194 50.6048C33.762 44.1273 32.7123 31.8505 41.8714 20.8686C46.4144 15.4214 51.8895 11.2914 58.291 8.47852L61.2434 13.2336C52.137 17.6464 45.3307 23.6295 44.3661 33.6605C48.4748 33.6605 51.8837 33.5117 54.92 34.5981C58.3769 35.8351 60.4766 38.1291 61.3383 40.7597C62.7053 44.8785 62.0426 49.0037 58.5199 52.2791Z"
        fill="url(#paint0_linear_955:2437)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_955:2437"
          x1="-4"
          y1="69.1642"
          x2="78.4435"
          y2="50.8285"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#830051" />
          <stop offset="1" stopColor="#FF6D42" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Quote
