import s from './styles.module.css'
import Check from '../payment-card/check'
import { Button } from 'components/design-system/button'
import { ArrowRightIcon } from 'components/design-system/kickoff-icons'
import cx from 'classnames'
import { LoadingIcon } from 'components/design-system/kickoff-icons'
import { TrackedA } from 'components/shared/analytics'
import { STANDARD_DETAILS_B } from 'constants/products'
import { useExperimentSplit } from 'utilities/experiment/context'

const PlanCardExperiment = ({
  products,
  options = { overrideDailyPrice: undefined },
  landingPageOptions = { overrideCTA: undefined },
  coupon,
  billingPeriodInMonths,
  dailyPrice,
  pricingDescription,
  onSelection = () => {},
  isLandingPage = false,
  isCoachPage = false,
  loading = false,
  isFreeTrial = false,
}) => {
  const livePush = useExperimentSplit('livePush')
  const dailyPriceDescription = options.overrideDailyPrice ?? '$' + dailyPrice
  return (
    <div>
      <div className={cx(s.container, { [s.landingContainer]: isLandingPage })}>
        <div className={cx(s.title, { [s.landingTitle]: isLandingPage })}>
          {!isLandingPage && (
            <span className={s.overline}>Program Details</span>
          )}
          <span>Kickoff Program</span>
        </div>
        <div className={s.left}>
          <div>
            <span className={s.price}>{dailyPriceDescription}</span>
            <span className={s.perDay}>/ day</span>
          </div>
        </div>
        <div className={s.right}>
          <div>
            {STANDARD_DETAILS_B.filter(detail => !detail.empty).map(
              (detail, index) => (
                <div className={s.listItem} key={index}>
                  <Check />
                  <span>{detail.text}</span>
                </div>
              )
            )}
          </div>
        </div>
        <div className={s.leftFooter}>
          <div className={s.billedSummary}>
            <span>{pricingDescription}</span>
          </div>
        </div>
        {isLandingPage &&
          !isFreeTrial &&
          !options.overrideDailyPrice &&
          !isCoachPage && (
            <div className={s.rightFooter}>
              <div className={s.liveCTA}>
                <Check />
                <span>{`Add live workouts - from $13 / session`}</span>
              </div>
            </div>
          )}
      </div>
      {isLandingPage && (
        <TrackedA name="Pricing CTA" onClick={onSelection}>
          <Button size="small" className={s.cta} disabled={loading}>
            {isFreeTrial
              ? 'Start Free Trial'
              : landingPageOptions.overrideCTA || 'Get Started'}
            {loading ? <LoadingIcon className={s.spin} /> : <ArrowRightIcon />}
          </Button>
        </TrackedA>
      )}
    </div>
  )
}

export default PlanCardExperiment
