import { useMemo } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { LandingPageAnalytics } from '../analytics'

import s from './styles.module.css'
import urls from 'utilities/urls'

const CoachSignupLink = ({
  text = 'Get started',
  className,
  buttonClassName,
  analyticProps = { location: '', label: null },
  style,
}) => {
  const { query } = useRouter()
  const href = useMemo(() => {
    return urls.coachSignUp({ ref: query?.ref }).as
  }, [query])

  /**
   * Redirect the user to the apply page, making sure
   * to pass on the `ref` query parameter if it is present
   * Also make sure to kick off and log an analytics event
   */
  const handleClick = () => {
    LandingPageAnalytics.onCoachApply({
      location: analyticProps.location,
      label: analyticProps.label,
      ref: query?.ref,
    })
  }

  return (
    <a
      href={href}
      className={cx(s.getStartedLink, className)}
      onClick={handleClick}>
      <button
        className={cx(s.buttonBase, buttonClassName || s.getStartedButton)}
        style={style}>
        <span>{text}</span>
      </button>
    </a>
  )
}

export default CoachSignupLink
