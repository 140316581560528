import * as R from 'ramda'
import { useState, useEffect } from 'react'
import cx from 'classnames'

import usePrevious from 'hooks/use-previous'
import NewCheckbox from 'components/shared/new-checkbox'
import OldCheckbox from 'components/shared/checkbox'
import { Checkbox as DesignSystemCheckbox } from 'components/design-system/inputs'
import SurveyQuestion from '..'
import s from './styles.module.css'
import Markdown from 'markdown-to-jsx'

const stringifyOptions = R.pipe(R.uniq, R.sort(R.comparator(R.lt)), R.join(','))

const optionsAreEqual = (a, b) => {
  return stringifyOptions(a || []) === stringifyOptions(b || [])
}

const Option = ({
  value,
  onClick,
  label,
  checked,
  response,
  subText,
  className,
  nestedSurveyQuestionProps,
  forceOldStyle,
  coachProfileVariant,
  isOnlyCheckboxClickable,
}) => {
  const Checkbox = forceOldStyle ? OldCheckbox : NewCheckbox

  if (coachProfileVariant === 'C') {
    return (
      <DesignSystemCheckbox
        label={label}
        value={value}
        checked={checked}
        onChange={onClick}
      />
    )
  }

  const NewTabLink = ({ href, children }) => (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )

  return (
    <li
      key={value}
      className={cx(
        forceOldStyle
          ? s.optionWrapper
          : isOnlyCheckboxClickable
          ? s.onlyCheckboxClickable
          : s.optionWrapperB,
        className,
        { [s.selected]: checked }
      )}
      onClick={!isOnlyCheckboxClickable ? onClick : undefined}>
      <div className={s.option}>
        <div onClick={isOnlyCheckboxClickable ? onClick : undefined}>
          <Checkbox checked={checked} />
        </div>
        <div className={forceOldStyle ? s.labelWrapper : s.labelWrapperB}>
          <span className={forceOldStyle ? s.label : s.labelB}>
            <Markdown
              options={{
                overrides: {
                  a: {
                    component: NewTabLink,
                  },
                },
              }}>
              {label}
            </Markdown>
          </span>
          {subText && <div className={s.subText}>{subText}</div>}
        </div>
      </div>
      {R.map(nestedQuestion => {
        if (!nestedQuestion.shouldDisplayTextIfNested && !checked) return null
        return (
          <div
            key={nestedQuestion.id}
            className={cx({
              [forceOldStyle ? s.nestedText : s.nestedTextB]:
                nestedQuestion.type === 'text' ||
                nestedQuestion.type === 'longText',
              [s.nestedSelect]:
                nestedQuestion.type === 'select' ||
                nestedQuestion.type === 'multiSelect',
            })}
            onClick={e => {
              e.stopPropagation()
            }}>
            <SurveyQuestion
              {...nestedSurveyQuestionProps}
              question={nestedQuestion}
              isNestedQuestion={true}
              forceOldStyle={forceOldStyle}
            />
          </div>
        )
      }, response?.nestedQuestions || [])}
    </li>
  )
}

const NestedMultiSelect = ({
  options,
  onChange,
  initialSelectedOptions,
  className,
  optionClassName,
  nestedSurveyQuestionProps,
  maxSelections = Infinity,
  isNestedQuestion,
  forceOldStyle,
  coachProfileVariant,
  isOnlyCheckboxClickable = false,
  description = null,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions || []
  )
  const previousInitialSelectedOptions = usePrevious(initialSelectedOptions)
  const previousSelectedOptions = usePrevious(selectedOptions)

  const selectedOptionsSet = new Set(selectedOptions)

  const toggleOption = value => {
    if (selectedOptionsSet.has(value)) {
      setSelectedOptions(R.reject(R.equals(value)))
    } else if (selectedOptions.length < maxSelections) {
      setSelectedOptions(R.append(value))
    }
  }

  useEffect(() => {
    if (
      previousSelectedOptions &&
      !optionsAreEqual(previousSelectedOptions, selectedOptions) &&
      onChange
    ) {
      onChange(selectedOptions)
    }
  }, [onChange, selectedOptions, previousSelectedOptions])

  useEffect(() => {
    if (
      previousInitialSelectedOptions &&
      !optionsAreEqual(previousInitialSelectedOptions, initialSelectedOptions)
    ) {
      setSelectedOptions(initialSelectedOptions)
    }
  }, [initialSelectedOptions, previousInitialSelectedOptions])

  return (
    <ol className={className}>
      {!forceOldStyle && !isNestedQuestion && (
        <span className={s.helperText}>
          {description || 'Select all that apply'}
        </span>
      )}
      {R.map(({ value, label, response, subText }) => {
        return (
          <Option
            key={value}
            value={value}
            label={label}
            onClick={() => toggleOption(value)}
            checked={selectedOptionsSet.has(value)}
            response={response}
            subText={subText}
            className={optionClassName}
            nestedSurveyQuestionProps={nestedSurveyQuestionProps}
            forceOldStyle={forceOldStyle}
            coachProfileVariant={coachProfileVariant}
            isOnlyCheckboxClickable={isOnlyCheckboxClickable}
          />
        )
      }, options)}
    </ol>
  )
}

export default NestedMultiSelect
