export default function RightArrowGradient() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 12H19"
        stroke="url(#paint0_linear_2129_80597)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="url(#paint1_linear_2129_80597)"
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2129_80597"
          x1="3.6"
          y1="13.29"
          x2="6.50073"
          y2="6.20857"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#830051" />
          <stop offset="1" stopColor="#FF6D42" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2129_80597"
          x1="11.3"
          y1="23.06"
          x2="21.318"
          y2="22.1866"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#830051" />
          <stop offset="1" stopColor="#FF6D42" />
        </linearGradient>
      </defs>
    </svg>
  )
}
