import cx from 'classnames'
import s from './styles.module.css'

export const PaymentCardHeader = ({ children, className }) => (
  <div className={cx(s.header, className)}>{children}</div>
)

export const PaymentCardContent = ({ children }) => (
  <div className={s.content}>{children}</div>
)

export const PaymentCardContentSection = ({ children }) => (
  <div className={s.contentSection}>{children}</div>
)

const PaymentCard = ({ children, className, style }) => {
  return (
    <div className={cx(s.container, className)} style={style}>
      {children}
    </div>
  )
}

export default PaymentCard
