import cx from 'classnames'
import { BaseTypography } from 'components/design-system/type-system'
import s from './styles.module.css'

const CoachCertifications = ({ certifications, bio = false }) => {
  return (
    <div className={cx(s.certifications, { [s.bio]: bio })}>
      <BaseTypography className={cx(s.subTitle, { [s.bio]: bio })} tag="h4">
        Certifications
      </BaseTypography>
      <ul className={s.certificationsList}>
        {certifications.map((certification, index) => (
          <li key={certification} className={s.certification}>
            {certification}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CoachCertifications
