import * as R from 'ramda'
import kickoffLogoWhite from 'images/kickoff-logo-white.svg'
import s from './styles.module.css'
import Instagram from './instagram'
import Facebook from './facebook'
import Twitter from './twitter'
import { getFormattedYear } from 'utilities/dates'
import { LandingPageExperimentAnalytics } from '../analytics'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import CmsPageContext from 'contexts/cms-page-context'
import { Fragment, useContext } from 'react'

const SocialIcons = {
  instagram: Instagram,
  facebook: Facebook,
  twitter: Twitter,
}

const FooterExperiment = ({ fields }) => {
  const { getRef } = useContext(CmsPageContext)
  const targetAudience = useTargetAudience()
  const year = getFormattedYear()

  const isGuestPass = targetAudience === Audience.GuestPass
  const firstCol = R.slice(0, 6, fields.primaryItems)
  const secondCol = R.slice(6, fields.primaryItems.length, fields.primaryItems)
  const thirdCol = R.filter(
    val =>
      !R.includes(val.fields.header.toLowerCase(), R.keys(SocialIcons)) &&
      !['terms', 'privacy'].some(subStr =>
        R.includes(subStr, val.fields.subHeader)
      ),
    fields.secondaryItems
  )
  const socialLinks = R.filter(
    val => R.includes(val.fields.header.toLowerCase(), R.keys(SocialIcons)),
    fields.secondaryItems
  )
  const bottomLinks = R.filter(
    val =>
      ['terms', 'privacy'].some(subStr =>
        R.includes(subStr, val.fields.subHeader)
      ),
    fields.secondaryItems
  )

  const handleNavClick = e => {
    const target = e.target.getAttribute('href').split('?')[0]

    if (target && target.includes('#')) {
      e.preventDefault()
      const yOffset = -100
      const element = document.querySelector(target)
      const y =
        element.getBoundingClientRect().top +
        window.pageYOffset +
        (target === `#success-stories` ? yOffset : -50)

      window.scrollTo({ top: y, behavior: 'smooth' })

      window.history.pushState(null, null, target)

      LandingPageExperimentAnalytics.onClick({
        location: `Bottom Nav - ${e.target.innerText}`,
      })
    }
  }

  return (
    <div className={s.container}>
      <div className={s.logo}>
        <img src={kickoffLogoWhite} alt="Kickoff" />
      </div>
      <div className={s.content}>
        <div className={s.internalLinks}>
          {R.addIndex(R.map)(
            ({ fields: { header: label, subHeader: url, text } }, idx) => {
              return (
                <a
                  key={`first-col-${idx}`}
                  className={s.internalLink}
                  href={
                    text && !getRef()
                      ? `${url}?ref=${text}`
                      : getRef()
                      ? `${url}?ref=${getRef()}`
                      : url
                  }
                  onClick={handleNavClick}>
                  {label}
                </a>
              )
            },
            firstCol
          )}
        </div>
        <div className={s.internalLinks}>
          {R.addIndex(R.map)(
            ({ fields: { header: label, subHeader: url, text } }, idx) => {
              return (
                <a
                  key={`secon-col-${idx}`}
                  className={s.internalLink}
                  href={
                    text && !getRef()
                      ? `${url}?ref=${text}`
                      : getRef()
                      ? `${url}?ref=${getRef()}`
                      : url
                  }
                  onClick={handleNavClick}>
                  {label}
                </a>
              )
            },
            R.reject(
              val => val.fields.header === 'Gifts' && isGuestPass,
              secondCol
            )
          )}
        </div>
        <div className={s.thridCol}>
          {R.addIndex(R.map)(
            ({ fields: { header: label, subHeader: url } }, idx) => {
              return (
                <a
                  key={`thrid-col-${idx}`}
                  className={s.internalLink}
                  href={url}>
                  {label}
                </a>
              )
            },
            thirdCol
          )}
          <div className={s.socalLinks}>
            {R.addIndex(R.map)(
              ({ fields: { header: text, subHeader: url } }, idx) => {
                const SocialIcon = SocialIcons[text.toLowerCase()]
                if (!SocialIcon) return null
                return (
                  <a
                    key={`social-links-${idx}`}
                    aria-label={text}
                    className={s.socialLink}
                    href={url}
                    onClick={() =>
                      handleNavClick({ target: { innerText: text } })
                    }>
                    <SocialIcon />
                  </a>
                )
              },
              socialLinks
            )}
          </div>
        </div>
      </div>
      <div className={s.termsAndCopyright}>
        <div className={s.border}></div>
        <div className={s.footerLinks}>
          <span>© {year} EverFit Inc. D/B/A Kickoff.</span>
          <span className={s.bullet}>•</span>
          <>
            {R.addIndex(R.map)(
              ({ fields: { header: label, subHeader: url, text } }, idx) => {
                return (
                  <Fragment key={`bottom-links-${idx}`}>
                    <a href={url} onClick={handleNavClick}>
                      {label}
                    </a>
                    {idx !== bottomLinks.length - 1 && (
                      <span className={s.bullet}>•</span>
                    )}
                  </Fragment>
                )
              },
              bottomLinks
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default FooterExperiment
