import * as R from 'ramda'
import cx from 'classnames'
import { COACH_CERTIFICATIONS_QUESTION_ID } from 'constants/cms-survey'
import EditButton from '../../edit-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachCertificationsProps extends CoachProps {
  bioCard?: boolean
}
const CoachCertifications = ({ coach, bioCard }: CoachCertificationsProps) => {
  return (
    <div className={s.container}>
      <EditButton
        questionId={COACH_CERTIFICATIONS_QUESTION_ID}
        isImageOverlay
      />
      {bioCard && <h6 className={s.header}>{'Certifications'}</h6>}
      <ul className={cx(bioCard ? s.bio : '', s.certifications)}>
        {R.map(
          certification => (
            <li key={certification} className={s.certification}>
              {certification}
            </li>
          ),
          bioCard
            ? R.take(4, coach.certifications)
            : coach.sourcingAuditPassed
            ? [...coach.certifications, 'Kickoff Certified']
            : coach.certifications
        )}
      </ul>
    </div>
  )
}

export default CoachCertifications
