import { useMemo } from 'react'

export const COACH_MATCH_PROMOTION_QUESTION_IDS = {
  WHY_I_LOVE_BEING_A_TRAINER: 199,
  MY_BACKGROUND: 200,
  MY_GO_TO_WORKOUT: 201,
  HEALTHY_FOOD_I_LOVE: 202,
  HEALTHY_FOOD_I_HATE: 203,
  FAVORITE_WORKOUT_MUSIC: 204,
  MOST_SURPRISED_TO_LEARN_ABOUT_ME: 205,
}

const useCoachSurveyResponses = ({ coach, questionIds }) => {
  return useMemo(() => {
    const res = coach.promotionQuestionResponses.reduce((acc, qr) => {
      if (questionIds.includes(parseInt(qr.surveyQuestionId, 10))) {
        acc.push({
          id: qr.surveyQuestionId,
          question: qr.question,
          response: qr.responseValue,
        })
      }
      return acc
    }, [])
    return res
  }, [coach, questionIds])
}
export default useCoachSurveyResponses
