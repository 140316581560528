import cx from 'classnames'
import * as R from 'ramda'
import s from './styles.module.css'
import { BaseTypography } from 'components/design-system/type-system'
import CoachSpecialties from './coach-specialties'
import CoachCertifications from './coach-certifications'
import TrainButtonExperiment from '../train-button'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import Image from 'next/image'
import Ribbon from '../assets/ribbon'
import StarRating from 'components/homepage/experiment/common/star-rating'
import { AnalyticsSection } from 'containers/analytics'
import { useAnalytics } from 'hooks/use-analytics'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachRatingProps {
  rating: number
  total: number
}

const CoachReviews = ({ rating, total }: CoachRatingProps) => {
  const logStarsClicked = useAnalytics('Star')

  const handleRatingsClick = () => {
    const reviews = document.getElementById('coachReviews')

    if (reviews) {
      const { top } = reviews.getBoundingClientRect()
      window?.scrollTo({ top: top - 100, behavior: 'smooth' })
    }

    logStarsClicked('Click')
  }

  return (
    !!rating && (
      <a
        className={cx(s.ratingsContainer)}
        onClick={handleRatingsClick}
        title="Scroll to reviews">
        <StarRating className={s.starRating} color="#F4A916" rating={rating} />
        <div className={s.ratingLabels}>
          <div>
            <span>{rating}</span>
            <span>out of 5</span>
          </div>
          <div>
            <span>{total}</span>
            <span>reviews</span>
          </div>
        </div>
      </a>
    )
  )
}

const CoachHeaderExperiment = ({ coach }: CoachProps) => {
  const targetAudience = useTargetAudience()
  const isGuestPass = targetAudience === Audience.GuestPass
  const isFreeTrial =
    targetAudience === Audience.FreeTrial ||
    targetAudience === Audience.CoachFreeTrial

  // const coachTags =
  //   coach.tags && coach.tags.length ? coach.tags : coach.tagsByVariant

  const coachFirstName = R.pathOr('', ['user', 'firstName'], coach)

  const rating =
    coach.rating && coach.rating.score
      ? parseFloat((coach.rating.score / 2).toFixed(1))
      : 0
  const total = coach.rating && coach.rating.total ? coach.rating.total : 0

  return (
    <div className={s.container}>
      <div className={cx(s.header, { [s.guestPassHeader]: isGuestPass })}>
        <div className={s.col}>
          <BaseTypography tag="h1" className={cx(s.title, s.desktop)}>
            {`Meet Coach ${coachFirstName}`}
          </BaseTypography>
          <AnalyticsSection name="Stars and Reviews Header">
            <CoachReviews rating={rating} total={total} />
          </AnalyticsSection>
          <div className={s.border}></div>
          <div className={s.credentials}>
            <div className={s.col}>
              <CoachSpecialties specialties={coach.specialties} />
            </div>
            <div className={s.col}>
              <CoachCertifications
                certifications={R.pathOr([], ['certifications'], coach)}
              />
            </div>
          </div>
          <TrainButtonExperiment
            coach={coach}
            className={s.headerCTA}
            name="Header CTA">
            {isFreeTrial
              ? `Start Free Trial with Coach ${R.pathOr(
                  '',
                  ['user', 'firstName'],
                  coach
                )}`
              : `Train with Coach ${R.pathOr(
                  '',
                  ['user', 'firstName'],
                  coach
                )}`}
          </TrainButtonExperiment>
        </div>
        <div className={s.col}>
          <BaseTypography tag="h1" className={cx(s.title, s.mobile)}>
            {`Meet Coach ${coachFirstName}`}
          </BaseTypography>
          <div className={s.topCoachContainer}>
            <div className={s.avatarContainer}>
              <div className={cx(s.avatar, { [s.topCoach]: coach.isTopCoach })}>
                <Image
                  src={R.pathOr('', ['profileImage', 'url'], coach)}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
              {coach.isTopCoach && <Ribbon className={s.topCoachRibbon} />}
            </div>
            {coach.isTopCoach && (
              <div className={s.topCoachLabel}>
                <span>Top Coach</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoachHeaderExperiment
