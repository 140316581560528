import { useState, useContext } from 'react'
import ModalContext from 'contexts/modal-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { Button, TextInput } from 'components/design-system'
import { useAnalytics } from 'hooks/use-analytics'

import s from './styles.module.css'
import { Body, Title } from 'components/design-system/type-system'
import { useExperimentSplit } from 'utilities/experiment/context'
import { LoadingIcon } from 'components/design-system/progress-button'
import { sleep } from 'utilities/promises'
import {
  ArrowRightIcon,
  CheckmarkIcon,
  PlusIcon,
} from 'components/design-system/kickoff-icons'

const EXPERIMENT_SPLITS = {
  FOLLOW_EMAIL: 'B',
  GET_IN_TOUCH: 'C',
  FOLLOW_INSTAGRAM: 'D',
}

const getInstagramLink = coach => {
  const instagramHandle = coach?.instagramHandle

  if (!instagramHandle) {
    return 'https://instagram.com'
  } else if (
    instagramHandle.startsWith('http') ||
    instagramHandle.startsWith('www')
  ) {
    return instagramHandle
  } else if (instagramHandle.startsWith('@')) {
    return `https://instagram.com/${instagramHandle.slice(1)}`
  } else {
    return `https://instagram.com/${instagramHandle}`
  }
}

const SubscribeModal = ({
  coach,
  onClose,
  onSubscribe,
}: {
  coach: any
  onClose: () => void
  onSubscribe: () => void
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  // the subscribe modal is a total smoke test for now
  // and nothing actually happens on submit
  const handleSubscribe = async event => {
    event.preventDefault()
    setIsSubmitting(true)
    await sleep(1000)
    setIsSuccess(true)
    setIsSubmitting(false)
    await sleep(1000)
    setIsSuccess(false)
    onSubscribe()
  }

  return (
    <div className={s.modalContent}>
      <Title size="xsmall">Sign Up for Updates</Title>
      <Body className={s.description} size="small">
        Enter your email to get wellness tips, free workouts, and news from{' '}
        {coach?.user?.firstName || 'me'}.
      </Body>

      <form onSubmit={handleSubscribe}>
        <TextInput
          className={s.input}
          inputProps={{ placeholder: 'Email' }}
          label="Email Address"
          fullWidth
        />

        <span className={s.modalActions}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            iconLeft={
              isSubmitting ? (
                <LoadingIcon size="medium" />
              ) : isSuccess ? (
                <CheckmarkIcon size="medium" />
              ) : null
            }
            className={s.button}
            type="submit">
            {isSubmitting
              ? 'Submitting'
              : isSuccess
              ? 'Subscribed'
              : 'Subscribe'}
          </Button>
        </span>
      </form>
    </div>
  )
}

const InstagramButton = ({
  coach,
  onClick,
}: {
  coach: any
  onClick: () => void
}) => {
  return (
    <a
      href={getInstagramLink(coach)}
      onClick={onClick}
      target="_blank"
      rel="noreferrer">
      <FontAwesomeIcon
        icon={faInstagram as any}
        color="#830051"
        className={s.instagramIcon}
        fontSize={32}
        title="Follow on Instagram"
      />
    </a>
  )
}

export const CoachMatchFollow = ({ coach }: { coach: any }) => {
  const experimentSplit = useExperimentSplit('followCoach')
  const logFollowAction = useAnalytics('Follow Coach CTA', {
    coachId: coach?.id,
  })
  const logSubscribeAction = useAnalytics('Confirm Email Subscription', {
    coachId: coach?.id,
  })

  const modal = useContext(ModalContext)

  const handleSubscribe = () => {
    logSubscribeAction('Click')
    modal.close()
  }

  const handleGetInTouch = () => {
    logFollowAction('Click')
    document.querySelector('#signUp').scrollIntoView({ behavior: 'smooth' })
  }

  const handleFollowOnInstagram = () => {
    logFollowAction('Click')
  }

  const handleOpenModal = () => {
    logFollowAction('Click')
    modal.open(
      () => (
        <SubscribeModal onClose={modal.close} onSubscribe={handleSubscribe} />
      ),
      {},
      {
        disableClose: false,
        key: 'CLP_SUBSCRIBE_MODAL',
        theme: 'black',
        className: s.modal,
      }
    )
  }

  if (experimentSplit === EXPERIMENT_SPLITS.FOLLOW_EMAIL) {
    return (
      <Button
        className={s.followButton}
        interactive
        variant="text"
        iconRight={<PlusIcon />}
        onClick={handleOpenModal}>
        Follow {coach?.user?.firstName || 'Me'}
      </Button>
    )
  } else if (experimentSplit === EXPERIMENT_SPLITS.GET_IN_TOUCH) {
    return (
      <Button
        className={s.followButton}
        interactive
        onClick={handleGetInTouch}
        variant="text"
        iconRight={<ArrowRightIcon />}>
        Get in touch
      </Button>
    )
  } else if (experimentSplit === EXPERIMENT_SPLITS.FOLLOW_INSTAGRAM) {
    return <InstagramButton coach={coach} onClick={handleFollowOnInstagram} />
  }

  return null
}
