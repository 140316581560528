import cx from 'classnames'
import * as R from 'ramda'
import { BaseTypography } from 'components/design-system/type-system'
import Quote from './quote'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachQuoteExperimentProps extends CoachProps {
  bio?: boolean
}
const CoachQuoteExperiment = ({
  coach,
  bio = false,
}: CoachQuoteExperimentProps) => {
  return (
    <div className={cx(s.container, { [s.bio]: bio })}>
      <Quote />
      <BaseTypography tag="h2" className={cx(s.quote, { [s.bio]: bio })}>
        {coach.quote}
      </BaseTypography>
      <BaseTypography tag="p" className={cx(s.name, { [s.bio]: bio })}>
        {`${
          bio ? 'My' : `${R.pathOr('', ['user', 'firstName'], coach)}'s`
        } mantra`}
      </BaseTypography>
    </div>
  )
}

export default CoachQuoteExperiment
