import cmsPageContext from 'contexts/cms-page-context'
import { useContext, useEffect, useMemo } from 'react'
import { LandingPageAnalytics } from '../analytics'
import s from './styles.module.css'
import { useRouter } from 'next/router'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import { getFirstStringFromOptionalArray } from 'utilities/strings'

const Banner = ({ fields, force }) => {
  const router = useRouter()
  const query = router.query

  const { isCoachPage, hasBanner } = useContext(cmsPageContext)
  const ref = getFirstStringFromOptionalArray(query?.ref) || ''
  const [platform, freetrial] = ref.split('_')

  const { header, options } = fields

  useEffect(() => {
    if (hasBanner || isCoachPage || force) {
      LandingPageAnalytics.onPromoBannerView({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trialDays = useClientTrialDays()

  const bannerText = useMemo(() => {
    if (isCoachPage) {
      if ((platform === 'gp' || trialDays) && options) return options.freetrial

      return header
    } else {
      if (options) return options[platform] || options[freetrial]

      return ''
    }
  }, [freetrial, header, isCoachPage, options, platform, trialDays])

  if (!hasBanner && !isCoachPage && !force) return null

  return (
    <section className={s.bannerSection}>
      <h3 className={s.header}>{bannerText}</h3>
    </section>
  )
}

export default Banner
