import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const WhatIsKickoff = ({ fields: { header, text, primaryImage } }) => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <h2 className={s.header}>{header}</h2>
        {primaryImage && (
          <div className={s.imageContainer}>
            <CmsImage
              className={s.image}
              fields={primaryImage?.fields}
              w={960}
            />
          </div>
        )}
        <p className={s.description}>{text}</p>
      </div>
    </section>
  )
}

export default WhatIsKickoff
