import Link from 'next/link'
import * as R from 'ramda'
import { useEffect, useState, useContext } from 'react'
import uuid from 'uuid/v1'

import CmsPageContext from 'contexts/cms-page-context'
import Spinner from 'components/shared/spinner'
import SurveyQuestion from 'components/shared/survey-question'
import useCurrentUser from 'hooks/use-current-user'
import { getAdjustedUtcOffset } from 'utilities/dates'
import ReengagementForm from 'components/shared/reengagement-form'
import urls from 'utilities/urls'
import s from './styles.module.css'

const FITNESS_GOAL_QUESTION_ID = 219
const FIRST_NAME_QUESTION_ID = 100
const EMAIL_QUESTION_ID = 132

const SignUpForm = ({
  surveyId,
  surveyQuestions,
  subHeader,
  onSubmit,
  onContinue,
  isSubmitting,
  errorMessage,
  infoMessage,
  onCancelReengagement,
  isReengagement = false,
}) => {
  const { isCoachPage, selectedPriceId } = useContext(CmsPageContext)
  const currentUserPayload = useCurrentUser()
  const currentUser = R.pathOr(null, ['data', 'me', 'user'], currentUserPayload)
  const currentClient = R.propOr(null, 'client', currentUser)
  const firstName = R.propOr(null, 'firstName', currentUser)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [responses, setResponses] = useState({})
  const [changeResponses, setChangeResponses] = useState({})

  const showCurrentClient = !!currentClient && !isSubmitting

  useEffect(() => {
    setMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    setMessage(infoMessage)
  }, [infoMessage])

  const responsesBySurveyQuestionId = R.map(response => {
    if (response.surveyQuestionResponseIds) {
      return R.map(surveyQuestionResponseId => {
        return {
          ...response,
          surveyQuestionResponseId,
        }
      }, response.surveyQuestionResponseIds)
    }

    return [response]
  }, responses)
  const responseBySurveyQuestionId = R.map(R.head, responsesBySurveyQuestionId)

  const handleRespond = (questionId, response) => {
    setError(null)

    if (!response) return setResponses(R.omit([questionId]))

    setResponses(R.assoc(questionId, { id: uuid(), ...response }))
  }

  const handleChangeResponse = (questionId, changeResponse) => {
    setError(null)

    if (!changeResponse) return setChangeResponses(R.omit([questionId]))

    setChangeResponses(R.assoc(questionId, { id: uuid(), ...changeResponse }))
  }

  const isValid = R.all(({ id, isRequired: defaultIsRequired }) => {
    let isRequired = defaultIsRequired

    if (id === FIRST_NAME_QUESTION_ID && isCoachPage) {
      isRequired = true
    }

    if (!isRequired) return true

    if (id === EMAIL_QUESTION_ID && isCoachPage) return true

    const response = responses[id]
    const changeResponse = changeResponses[id]

    if (changeResponse && changeResponse.isValid) return true

    return (
      !!response &&
      (response.responseValue || response.surveyQuestionResponseIds)
    )
  }, surveyQuestions)

  const handleSubmit = ev => {
    ev && ev.preventDefault()

    if (isSubmitting) return
    if (!isValid) {
      return setError('Please provide first name and phone')
    }

    const responseParams = R.reject(
      R.isNil,
      R.map(({ id, modelPath }) => {
        if (!changeResponses[id] && !responses[id]) {
          return null
        }

        const { responseValue, surveyQuestionResponseIds } = changeResponses[id]
          ? {
              responseValue: changeResponses[id].value,
            }
          : responses[id]

        return {
          surveyId,
          surveyQuestionId: id,
          modelPath,
          responseValue,
          surveyQuestionResponseIds,
        }
      }, surveyQuestions)
    )

    const fitnessGoalResponseIds = new Set(
      R.propOr(
        [],
        'surveyQuestionResponseIds',
        responseBySurveyQuestionId[FITNESS_GOAL_QUESTION_ID]
      )
    )
    const fitnessGoals = R.pipe(
      R.find(R.propEq('id', FITNESS_GOAL_QUESTION_ID)),
      R.propOr([], 'responses'),
      R.filter(({ id }) => fitnessGoalResponseIds.has(R.toString(id))),
      R.map(R.prop('text'))
    )(surveyQuestions)

    const utcOffset = getAdjustedUtcOffset()

    onSubmit({ utcOffset, fitnessGoals, responseParams })
  }

  let content

  if (showCurrentClient) {
    content = (
      <div className={s.form}>
        {firstName && (
          <div className={s.welcomeBack}>Welcome back, {firstName}!</div>
        )}
        {!firstName && <div className={s.welcomeBack}>Welcome back!</div>}
        {onContinue && (
          <button className={s.continueButton} onClick={onContinue}>
            Continue sign up
          </button>
        )}
        {!onContinue && (
          <Link {...urls.clientSignUp()}>
            <button className={s.continueButton}>Continue sign up</button>
          </Link>
        )}
      </div>
    )
  } else if (isReengagement) {
    content = (
      <div className={s.form}>
        <ReengagementForm
          onRetry={handleSubmit}
          onCancelReengagement={onCancelReengagement}
        />
      </div>
    )
  } else {
    content = (
      <form className={s.form} onSubmit={handleSubmit}>
        <div>
          <label className={s.goalsLabel}>Select your goal(s)</label>
          {R.map(question => {
            return (
              <SurveyQuestion
                key={question.id}
                respond={response => handleRespond(question.id, response)}
                question={question}
                responseBySurveyQuestionId={responseBySurveyQuestionId}
                responsesBySurveyQuestionId={responsesBySurveyQuestionId}
                inputClassName={s.input}
                selectClassName={s.select}
                selectOptionClassName={s.selectOption}
                setOnChangeResponse={changeResponse => {
                  handleChangeResponse(question.id, changeResponse)
                }}
                hideHeader={true}
                hideNote={true}
                forceOldStyle
              />
            )
          }, surveyQuestions)}
        </div>
        {error && <div className={s.error}>{error}</div>}
        {message && <div className={s.message}>{message}</div>}
        <button className={s.submitButton} type="submit">
          {isSubmitting && <Spinner className={s.spinner} />}
          {!isSubmitting && 'Continue'}
        </button>
      </form>
    )
  }

  return (
    <div className={s.formWrapper}>
      <h4 className={s.formLabel}>{subHeader}</h4>
      {content}
    </div>
  )
}

export default SignUpForm
