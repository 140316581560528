import * as R from 'ramda'

const buildResponse = ({ fields }) => ({
  ...fields,
  nestedQuestions: R.map(buildQuestion, fields.nestedQuestions || []),
})

const buildQuestion = ({ fields }) => ({
  ...fields,
  responses: R.map(buildResponse, fields.responses || []),
})

export const buildSections = ({ survey, isAdmin, includeQuestionIds }) => {
  const filterQuestions = R.filter(({ id, isAdminOnly }) => {
    if (isAdminOnly && !isAdmin) return false
    if (includeQuestionIds && !includeQuestionIds.has(id)) return false

    return true
  })

  return R.pipe(
    R.path(['fields', 'sections']),
    R.map(section => ({
      id: section.sys.id,
      name: section.fields.name,
      heading: section.fields.heading,
      description: section.fields.description,
      groups: R.filter(
        ({ questions }) => questions.length > 0,
        [
          ...R.map(
            question => ({
              id: question.sys.id,
              questions: filterQuestions([buildQuestion(question)]),
            }),
            section.fields.questions
          ),
          ...R.map(
            nestedSection => ({
              ...nestedSection.fields,
              id: nestedSection.sys.id,
              questions: filterQuestions(
                R.map(buildQuestion, nestedSection.fields.questions)
              ),
            }),
            section.fields.nestedSections || []
          ),
        ]
      ),
    }))
  )(survey)
}

export const getResponseFeedback = ({ responseId, question }) => {
  const matchedResponse = question?.responses?.find(
    response => parseInt(response.id) === parseInt(responseId)
  )

  return matchedResponse?.surveyQuestionResponseFeedback?.[0]?.fields
}
