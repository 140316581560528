import { useContext } from 'react'
import NextImage from 'next/image'

import CmsPageContext from 'contexts/cms-page-context'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import s from './styles.module.css'

export function HeroMedia({ imageFields, videoFields }) {
  const { isSafari } = useContext(CmsPageContext)
  const fm = isSafari ? 'jpg' : 'webp'

  const enableVideo = useWindowSizeCondition(({ width }) => width > 992, false)

  return (
    <div className={s.container}>
      <NextImage
        alt={imageFields.title}
        src={`https:${imageFields.file.url}?fm=${fm}`}
        layout="responsive"
        objectFit="initial"
        width={imageFields.file.details.image.width}
        height={imageFields.file.details.image.height}
        priority
      />
      {enableVideo && videoFields && (
        <video
          className={s.video}
          src={`https:${videoFields.file.url}`}
          loop
          autoPlay={true}
          playsInline={true}
          muted={true}
        />
      )}
      <div className={s.overlay} />
    </div>
  )
}
