import GetStartedLink from '../get-started-link'
import CoachSignupLink from '../coach-signup-link'
import { Audience } from 'hooks/use-target-audience'

/**
 * Utility component used to display the correct button
 * link component based on if the landing page is intended
 * for prospective clients or prospective coaches
 */
const CTALink = ({
  analyticProps,
  buttonText,
  buttonClassName,
  className,
  promptText,
  promptTextClassName,
  spinnerClassName,
  targetAudience = Audience.Client,
  name,
}) => {
  if (targetAudience === Audience.Coach) {
    return (
      <div className={className}>
        <div className={promptTextClassName}>{promptText}</div>
        <CoachSignupLink
          buttonClassName={buttonClassName}
          text={buttonText}
          analyticProps={analyticProps}
        />
      </div>
    )
  } else {
    if (promptText) {
      return (
        <div className={className}>
          <div className={promptTextClassName}>{promptText}</div>
          <GetStartedLink
            analyticProps={analyticProps}
            buttonClassName={buttonClassName}
            spinnerClassName={spinnerClassName}
            text={buttonText}
            name={name}
          />
        </div>
      )
    }
    return (
      <GetStartedLink
        analyticProps={analyticProps}
        className={className}
        buttonClassName={buttonClassName}
        spinnerClassName={spinnerClassName}
        text={buttonText}
        name={name}
      />
    )
  }
}

export default CTALink
