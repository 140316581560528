import { BaseTypography } from 'components/design-system/type-system'
import SectionTitle from '../common/section-title'
import s from './styles.module.css'

const CoachMissionExperiment = ({ coach }) => {
  return (
    <div className={s.container}>
      <SectionTitle title="My Mission" />
      <BaseTypography tag="p" className={s.body}>
        {coach.missionStatement}
      </BaseTypography>
    </div>
  )
}

export default CoachMissionExperiment
