import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import * as R from 'ramda'
import { getLiveUpgradePrice } from 'utilities/stripe'

import s from './styles.module.css'
import { billingPeriodInWords } from 'components/payment/helpers'
import { Body, Title } from 'components/design-system/type-system'

const billingMonthAbbreviation = (num: number) => {
  if (num === 1) return 'month'
  if (num === 6) return '6 mo'
  if (num === 12) return 'yr'
  return ''
}

const priceDisplay = (price: number) => {
  return price % 1 === 0 ? price : price.toFixed(2)
}

interface ILiveWorkoutSelectionProps {
  products: any[]
  product: any
  coupon: any
  coachName: string
  billingPeriodInMonths: number
  onSelection: (product: any, price: number) => void
}

const LiveWorkoutSelection = ({
  products,
  product,
  coupon,
  coachName,
  billingPeriodInMonths,
  onSelection,
}: ILiveWorkoutSelectionProps) => {
  const liveUpgradePrice = getLiveUpgradePrice({
    coupon,
    products,
    billingPeriodInMonths,
    sessions: 1,
  })

  const livePlusUpgradePrice = getLiveUpgradePrice({
    coupon,
    products,
    billingPeriodInMonths,
    sessions: 2,
  })

  const options = useMemo(
    () => [
      {
        label: 'No Live session',
        description: '',
        product: products[0],
        price: `+ $0`,
      },
      {
        label: '1 weekly Live session',
        description: `$${liveUpgradePrice} paid ${
          billingPeriodInMonths === 6 ? 'every ' : ''
        }${billingPeriodInWords(billingPeriodInMonths)} with 4 sessions total`,
        product: products[1],
        price: `+ $${liveUpgradePrice}/${billingMonthAbbreviation(
          billingPeriodInMonths
        )}`,
        perSessionPrice: `$${priceDisplay(
          liveUpgradePrice / (4 * billingPeriodInMonths)
        )}/session`,
      },
      {
        label: '2 weekly Live sessions',
        description: `$${livePlusUpgradePrice} paid  ${
          billingPeriodInMonths === 6 ? 'every ' : ''
        }${billingPeriodInWords(billingPeriodInMonths)} with 8 sessions total`,
        product: products[2],
        price: `+ $${livePlusUpgradePrice}/${billingMonthAbbreviation(
          billingPeriodInMonths
        )}`,
        perSessionPrice: `$${priceDisplay(
          livePlusUpgradePrice / (8 * billingPeriodInMonths)
        )}/session`,
      },
    ],
    [billingPeriodInMonths, livePlusUpgradePrice, liveUpgradePrice, products]
  )

  const [liveSelection, setLiveSelection] = useState(0)

  const handleLiveSelection = (idx: number) => {
    setLiveSelection(idx)
    const price = R.find(
      R.propEq('billingPeriodInMonths', billingPeriodInMonths),
      options[idx].product.prices
    )
    onSelection(options[idx].product, price)
  }

  useEffect(() => {
    setLiveSelection(
      R.findIndex(R.pathEq(['product', 'id'], product.id), options)
    )
  }, [options, product])

  return (
    <div className={s.container}>
      <Title size="large" weight="bold" className={s.title}>
        Add weekly Live video workouts
      </Title>
      <Body size="small" weight="regular" className={s.body}>
        Each Live session is a 30 to 45 minute one-on-one video call with{' '}
        {coachName}.
      </Body>
      <div className={s.selections}>
        {options.map((option: any, index: number) => (
          <div className={s.item} key={option.product.id}>
            <button
              className={cx(s.button, {
                [s.selected]: index === liveSelection,
              })}
              onClick={() => handleLiveSelection(index)}>
              <div>
                <span>{option.label}</span>
                {option.perSessionPrice && (
                  <span className={s.description}>
                    {option.perSessionPrice}
                  </span>
                )}
              </div>
              <span>{option.price}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LiveWorkoutSelection
