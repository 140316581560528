import * as R from 'ramda'
import cx from 'classnames'
import PlanSelectionExperiment from '../../../payment/payment-variants/experiment/plan-selection'
import useProducts from 'hooks/use-products'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import { useContext, useState } from 'react'
import { NAV_LINKS } from '../nav/constants'
import { LandingPageExperimentAnalytics } from '../analytics'
import { getDailyPrice, getTrialDaysDuration } from 'utilities/stripe'
import Router from 'next/router'
import { useExperiment } from 'utilities/experiment/context'
import { AnalyticsSection } from 'containers/analytics'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import { capitalize } from 'utilities/strings'
import PlanSelection from 'components/payment/payment-variants/control/plan-selection'
import Pricing from 'components/homepage/pricing'

const PricingExperiment = ({ fields, landingPageOptions }) => {
  const trialDays = useClientTrialDays()
  const clp = useExperiment('clp')
  const livePush = useExperiment('livePush')

  const { signUp, coupon, isCoachPage, selectPriceId, coach, query } =
    useContext(CmsPageContext)

  const productsPayload = useProducts({ coachId: coach?.id })
  const products = productsPayload.data?.products || []

  const isOfferingLiveVideoWorkouts = coach
    ? coach.isOfferingLiveVideoWorkouts
    : true

  const [submittingProductId, setSubmittingProductId] = useState()

  const handleSelection = async (product, price) => {
    if (submittingProductId) return
    const priceId = price.id

    if (isCoachPage) {
      selectPriceId(priceId)

      if (clp.split && clp.split === 'B') {
        const { id, ...queryParams } = query
        return Router.push({ pathname: `${id}/sign-up`, query: queryParams })
      }

      return document
        .querySelector('#signUp')
        .scrollIntoView({ behavior: 'smooth' })
    }

    setSubmittingProductId(product.id)
    await signUp({ surveyParams: { priceId } })

    LandingPageExperimentAnalytics.onClick({
      location: `Pricing - ${product.descriptiveName} CTA`,
    })
  }

  const [dailyPriceInDollars] =
    products && products.length
      ? getDailyPrice({
          price: products[0].prices.find(p => p.billingPeriodInMonths === 1),
          coupon,
          isFree: !!trialDays,
        })
      : [3]

  const period = getTrialDaysDuration(trialDays)
  const periodFormatted = period && capitalize(period)

  if (livePush.split === 'A' && isCoachPage) {
    return <Pricing fields={fields} dailyPriceInDollars={dailyPriceInDollars} />
  }

  let PlanSelectionComponent =
    livePush.split === 'B' ? PlanSelectionExperiment : PlanSelection

  if (livePush.split === 'B' && isCoachPage && !isOfferingLiveVideoWorkouts) {
    PlanSelectionComponent = PlanSelection
  }

  return (
    <AnalyticsSection name="Pricing">
      <section
        id={NAV_LINKS.pricing}
        className={cx(s.container, { [s.coachBackground]: isCoachPage })}>
        <h3 className={s.title}>
          {fields.header
            .replace(/{price}/g, dailyPriceInDollars)
            .replace('{period}', periodFormatted)}
        </h3>
        <div className={cx(s.terms, s.mobile)}>
          <span>No cancellation fees</span>
          <span>No contracts</span>
          <span>Cancel anytime</span>
        </div>
        <PlanSelectionComponent
          products={R.addIndex(R.filter)(
            (p, i) =>
              isCoachPage && !isOfferingLiveVideoWorkouts ? i === 0 : true,
            products
          )}
          options={fields.options}
          landingPageOptions={landingPageOptions}
          coupon={coupon}
          showPlanDuration={
            !isCoachPage && !trialDays && !fields.options?.overrideDailyPrice
          }
          isLandingPage
          isCoachPage={isCoachPage}
          onSelection={handleSelection}
          className={s.planSelection}
          loading={!!submittingProductId}
          trialDays={trialDays}
        />
        <div className={cx(s.terms, s.desktop)}>
          <span>No cancellation fees</span>
          <span>No contracts</span>
          <span>Cancel anytime</span>
        </div>
      </section>
    </AnalyticsSection>
  )
}

export default PricingExperiment
