import * as R from 'ramda'
import { useState, useEffect } from 'react'

import cx from 'classnames'
import s from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextInput } from 'components/design-system/inputs'

const SurveyQuestionTextInput = props => {
  const {
    value: initialValue,
    error: propError,
    label,
    inputClassName,
    wrapperClassName,
    errorClassName,
    icon,
    placeholder = '',
    onChange = () => {},
    onChangeDetailed = () => {},
    onBlur = () => {},
    getError = () => null,
    parseValue = R.identity,
    formatValue = R.identity,
    isInvalid = false,
    isV2 = false,
    isRequired = false,
    type = 'text',
    coachProfileVariant,
    ...inputProps
  } = props

  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState(
    initialValue ? getError(initialValue) : null
  )

  useEffect(() => {
    handleBlur()
  }, [])

  const handleChange = ev => {
    const newValue = parseValue(ev.target.value)

    setError(null)
    setValue(newValue)

    onChange(newValue)

    const error = getError(newValue)
    setError(error)
    onChangeDetailed({
      value: newValue,
      error,
      isValid: !error && !isInvalid && (!isRequired || !!newValue),
    })
  }

  const handleBlur = () => {
    const error = getError(value)
    if (error) return setError(error)

    onBlur(value)
  }

  const onKeyDown = ev => {
    if (props.onKeyDown) {
      props.onKeyDown(ev)
    }
    if (ev.key === 'ArrowLeft' || ev.key === 'ArrowRight') {
      ev.stopPropagation()
    }
  }

  if (coachProfileVariant === 'C') {
    return (
      <TextInput
        {...inputProps}
        type={type}
        label={placeholder}
        value={formatValue(value)}
        onChange={handleChange}
        onBlur={handleBlur}
        className={inputClassName}
      />
    )
  }

  return (
    <div className={cx(s.wrapper, wrapperClassName)}>
      {label && <div className={s.label}>{label}</div>}
      <div className={s.inputWrapper}>
        {icon && <FontAwesomeIcon icon={icon} className={s.icon} />}
        <input
          {...inputProps}
          type={type}
          placeholder={placeholder}
          value={formatValue(value)}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          className={cx(
            {
              [s.inputInvalid]: isInvalid || !!error,
              [s.input]: !isV2,
              [s.inputV2]: isV2,
              [s.iconInput]: !!icon,
            },
            inputClassName
          )}
          onClick={e => {
            e.stopPropagation()
          }}
        />
      </div>
      {(error || propError) && (
        <div className={errorClassName || s.error}>{error || propError}</div>
      )}
    </div>
  )
}

export default SurveyQuestionTextInput
