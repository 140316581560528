import * as R from 'ramda'
import cx from 'classnames'
import {
  BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS,
  DETAILS_BY_CALLS_PER_WEEK_B,
  OLD_PRICING_DESCRIPTION,
} from 'constants/products'
import {
  getDailyPrice,
  getDiscountedPrice,
  getProductTitle,
} from 'utilities/stripe'
import s from './styles.module.css'
import PlanDurationSelection from '../../control/plan-duration-selection'
import { useEffect, useRef, useState } from 'react'
import { billingPeriodInWords } from '../../../helpers'
import { formatCurrency } from 'utilities/numbers'
import PlanCardExperiment from './plan-card'
import LiveWorkoutSelection from '../live-workout-selection'

const BILLING_PERIOD_IN_MONTHS = 1

const PlanSelection = ({
  products,
  product,
  coupon,
  showPlanDuration = false,
  onSelection,
  billingPeriodInMonths: billingPeriodInMonthsProp = BILLING_PERIOD_IN_MONTHS,
  className = '',
  isLandingPage = false,
  loading = false,
  isFreeTrial,
  isGuestPass,
  isTYPGuestPass,
  showLiveOption = false,
}) => {
  const plansRef = useRef()
  const [billingPeriodInMonths, setBillingPeriodInMonths] = useState(
    billingPeriodInMonthsProp
  )

  const handleDurationSelection = months => {
    if (showPlanDuration) setBillingPeriodInMonths(months)
  }

  useEffect(() => {
    if (!isLandingPage) {
      setBillingPeriodInMonths(billingPeriodInMonthsProp)
    }
  }, [billingPeriodInMonthsProp, isLandingPage])

  useEffect(() => {
    if (plansRef.current) {
      plansRef.current.scrollLeft = window?.innerWidth - 32
    }
  }, [])

  return (
    <div className={cx(s.planSelectionContainer, className)}>
      {showPlanDuration && (
        <div className={s.planSelection}>
          <PlanDurationSelection
            duration={billingPeriodInMonths}
            onChange={handleDurationSelection}
          />
        </div>
      )}
      <div ref={plansRef} className={s.plans}>
        {R.map(p => {
          const firstMonthPrice = R.find(
            R.propEq('billingPeriodInMonths', 1),
            p.prices
          )

          const firstMonthPriceInDollars =
            getDiscountedPrice({
              price: firstMonthPrice,
              coupon,
              isFirstTerm: true,
            }) / 100

          const formattedFirstMonthPriceInDollars =
            firstMonthPriceInDollars % 1 === 0
              ? formatCurrency(firstMonthPriceInDollars, {
                  minimumFractionDigits: 0,
                })
              : formatCurrency(firstMonthPriceInDollars, {
                  minimumFractionDigits: 2,
                })

          const price = R.find(
            R.propEq('billingPeriodInMonths', billingPeriodInMonths),
            p.prices
          )
          const [dailyPriceInDollars] = getDailyPrice({
            price,
            coupon,
            isFree: isFreeTrial || isGuestPass || isTYPGuestPass,
          })
          const [dailyPriceInDollarsWithOutCoupon] = getDailyPrice({ price })
          const firstTermPriceInDollars = Math.round(
            getDiscountedPrice({
              price,
              coupon,
              isFirstTerm: true,
            }) / 100
          )
          const secondTermPriceInDollars = Math.round(
            getDiscountedPrice({
              price,
              coupon,
              isFirstTerm: false,
            }) / 100
          )
          const isOneTermDiscount =
            firstTermPriceInDollars !== secondTermPriceInDollars

          const billingTermText =
            BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS[
              price.billingPeriodInMonths
            ]
          const priceDescriptionSubtext = `${
            price.unitAmount !== 9500 ? 'New: ' : ''
          } $${secondTermPriceInDollars} billed ${
            price.billingPeriodInMonths === 6 ? 'every' : ''
          } ${billingPeriodInWords(price.billingPeriodInMonths)}`

          const pricingDescription = isOneTermDiscount
            ? `First ${
                isTYPGuestPass
                  ? 'week free'
                  : isGuestPass
                  ? 'month free'
                  : isFreeTrial
                  ? '7 days free'
                  : coupon
                  ? `month only ${formattedFirstMonthPriceInDollars}`
                  : `${billingTermText} only $${firstTermPriceInDollars}`
              } - then ${
                isFreeTrial && !isGuestPass
                  ? `$${
                      dailyPriceInDollars === 0
                        ? dailyPriceInDollarsWithOutCoupon
                        : dailyPriceInDollars
                    }/day${
                      isLandingPage ? '' : ` (${priceDescriptionSubtext})`
                    }`
                  : `${priceDescriptionSubtext}`
              }`
            : `${priceDescriptionSubtext}`

          const oldPricingDescription = OLD_PRICING_DESCRIPTION[p.callsPerWeek]

          return (
            <div key={p.id} className={s.planWrapper}>
              <PlanCardExperiment
                product={product}
                price={price}
                title={getProductTitle(p)}
                dailyPrice={dailyPriceInDollars}
                pricingDescription={pricingDescription}
                oldPricingDescription={oldPricingDescription}
                details={DETAILS_BY_CALLS_PER_WEEK_B[p.callsPerWeek]}
                recommended={p.isRecommended}
                onSelection={() => onSelection(p, price)}
              />
              {!isLandingPage &&
                !isFreeTrial &&
                showLiveOption &&
                !isGuestPass && (
                  <LiveWorkoutSelection
                    products={products}
                    product={product}
                    coupon={coupon}
                    billingPeriodInMonths={billingPeriodInMonths}
                    onSelection={onSelection}
                  />
                )}
            </div>
          )
        }, R.take(3, products))}
      </div>
    </div>
  )
}

export default PlanSelection
