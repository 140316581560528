import { useContext } from 'react'

import CmsPageContext from 'contexts/cms-page-context'
import { AnalyticsSection } from 'containers/analytics'
import { useExperiment } from 'utilities/experiment/context'
import CoachProfileExperiment from '../coach-variants/experiment'
import CoachProfileExperimentDesignSystem from '../coach-variants/experiment-design-system'
import CoachProfileControl from '../coach-variants/control'

export interface CoachProps {
  coach: {
    id: number
    profileImage?: {
      url: string
    }
    actionImage?: {
      url: string
    }
    bodyImage?: {
      url: string
    }
    humanizingImage?: {
      url: string
    }
    rating: {
      score: number
      total: number
    }
    specialties: any[]
    certifications: any[]
    missionStatement: string
    isTopCoach: boolean
    quote: string
    user: {
      firstName: string
      username: string
    }
    tags?: any[]
    tagsByVariant?: any[]
    introVideo?: {
      url: string
    }
    promotionQuestionResponses?: any[]
    whyTrainWithMe: string
    reviews: any[]
    sourcingAuditPassed: boolean
  }
}

const CoachProfileHero = ({ fields: defaultHeroFields }) => {
  const { coach, onAnchorLinkClick } = useContext(CmsPageContext)
  const clp = useExperiment('clp')

  if (!coach) {
    return null
  }

  if (clp.split && clp.split === 'B') {
    return (
      <CoachProfileExperiment coach={coach} onCtaClick={onAnchorLinkClick} />
    )
  } else if (clp?.split === 'C' || clp?.split === 'D') {
    return (
      <CoachProfileExperimentDesignSystem
        coach={coach}
        onCtaClick={onAnchorLinkClick}
      />
    )
  }

  return (
    <AnalyticsSection name="Coach">
      <CoachProfileControl coach={coach} onCtaClick={onAnchorLinkClick} />
    </AnalyticsSection>
  )
}

export default CoachProfileHero
