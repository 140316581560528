const Dumbbell = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_955:3066)">
        <path
          d="M7.5 13H16.5"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M17.5 20C17.1022 20 16.7206 19.842 16.4393 19.5607C16.158 19.2794 16 18.8978 16 18.5V6.5C16 6.10218 16.158 5.72064 16.4393 5.43934C16.7206 5.15804 17.1022 5 17.5 5V5C17.8978 5 18.2794 5.15804 18.5607 5.43934C18.842 5.72064 19 6.10218 19 6.5V18.5C19 18.8978 18.842 19.2794 18.5607 19.5607C18.2794 19.842 17.8978 20 17.5 20Z"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M6.5 20C6.10218 20 5.72064 19.842 5.43934 19.5607C5.15804 19.2794 5 18.8978 5 18.5V6.5C5 6.10218 5.15804 5.72064 5.43934 5.43934C5.72064 5.15804 6.10218 5 6.5 5V5C6.89782 5 7.27936 5.15804 7.56066 5.43934C7.84196 5.72064 8 6.10218 8 6.5V18.5C8 18.8978 7.84196 19.2794 7.56066 19.5607C7.27936 19.842 6.89782 20 6.5 20Z"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M2.25 8.5V17.5"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M2.25 13H0.75"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M21.75 8.5V17.5"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M21.75 13H23.25"
          stroke="#212121"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_955:3066">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Dumbbell
