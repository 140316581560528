export const SIGN_UP_EXPERIMENT = {
  id: 'AR8OTFuPgABiXEBMlacA0',
  name: 'signUp',
  variants: ['Z', 'AA'],
}

export const COACH_PROFILE_EXPERIMENT = {
  id: '6DmwlPF3PQdO7zvZbjXbTp',
  name: 'coachProfile',
  variants: ['B', 'E'],
}

export const WEB_PROJECT_CONTENTFUL_ENTRY_ID = '3eGlEWe7stbvsy6U8l1XUf'
export const LANDING_PAGES_PROJECT_CONTENTFUL_ENTRY_ID = 'xvzWA4h56VQpkicdXzyIx'
export const CLIENT_RECRUITER_CAPTION_ENTRY_ID = '5iPPep3jfL0wQU0fvBsKnT'

export const HOMEPAGE_CONTENTFUL_SLUG = '_reskin'
export const WEIGHT_LOSS_CONTENTFUL_SLUG = 'best-way-to-lose-weight'
export const PERSONAL_TRAINERS_CONTENTFUL_SLUG = 'personal-trainers'

const IS_PROD = process.env.APP_ENV === 'production'

export const COUPON_CODE = {
  dollars19Once: IS_PROD ? 'JOdLpa9j' : 'TWEozk5e',
  percent50Once: IS_PROD ? 'IG8B5qI7' : '8YVTMxGK',
}
