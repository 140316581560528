import { COACH_MISSION_QUESTION_ID } from 'constants/cms-survey'
import EditButton from '../../edit-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const CoachMission = ({ coach }: CoachProps) => {
  return (
    <div className={s.container}>
      <h6 className={s.header}>
        My Mission
        <EditButton questionId={COACH_MISSION_QUESTION_ID} />
      </h6>
      <div className={s.mission}>{coach.missionStatement}</div>
    </div>
  )
}

export default CoachMission
