import * as R from 'ramda'
import { BaseTypography } from 'components/design-system/type-system'
import Section from '../common/section'
import SectionColumn from '../common/section-column'
import SectionImage from '../common/section-image'
import SectionTitle from '../common/section-title'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const CoachWhyTrainWithMeExperiment = ({ coach }: CoachProps) => {
  return (
    <Section wrap="wrapReverse">
      <SectionColumn>
        <SectionTitle
          subtitle={`Why ${R.pathOr('', ['user', 'firstName'], coach)}`}
          title="Why Train With Me"
        />
        <BaseTypography className={s.body}>
          {coach.whyTrainWithMe}
        </BaseTypography>
      </SectionColumn>
      <SectionColumn>
        {coach.actionImage?.url && (
          <SectionImage src={coach.actionImage?.url} alt="Coach Action" />
        )}
      </SectionColumn>
    </Section>
  )
}

export default CoachWhyTrainWithMeExperiment
