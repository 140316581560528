import Link from 'next/link'
import kickoffLogoDark from 'images/kickoff-logo-dark.svg'
import urls from 'utilities/urls'
import cx from 'classnames'
import { useContext } from 'react'
import CmsPageContext from 'contexts/cms-page-context'
import useScrollThreshold from 'hooks/use-scroll-threshold'
import s from './styles.module.css'

export default function BlankNav({ fields }) {
  const { hasBanner } = useContext(CmsPageContext)
  const scroll = useScrollThreshold(1)

  const linkProps = fields?.options?.logoHref
    ? { href: fields.options.logoHref, as: fields.options.logoHref }
    : urls.homePage()

  return (
    <div
      className={cx(s.container, {
        [s.shadow]: scroll,
        [s.banner]: hasBanner,
      })}>
      <Link {...linkProps} className={s.logoContainer}>
        <img src={kickoffLogoDark} alt="Kickoff" className={s.logo} />
      </Link>
    </div>
  )
}
