import * as R from 'ramda'
import useCoachSurveyResponses, {
  COACH_MATCH_PROMOTION_QUESTION_IDS,
} from 'hooks/use-coach-survey-responses'
import { BaseTypography } from 'components/design-system/type-system'
import Apple from '../assets/apple'
import Dumbbell from '../assets/dumbbell'
import Frowny from '../assets/frowny'
import Section from '../common/section'
import SectionColumn from '../common/section-column'
import SectionImage from '../common/section-image'
import SectionTitle from '../common/section-title'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const Icons = {
  [COACH_MATCH_PROMOTION_QUESTION_IDS.MY_GO_TO_WORKOUT]: Dumbbell,
  [COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_LOVE]: Apple,
  [COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_HATE]: Frowny,
}

const CoachGettingToKnowMeExperiment = ({ coach }: CoachProps) => {
  const coachResponses = useCoachSurveyResponses({
    coach,
    questionIds: [
      COACH_MATCH_PROMOTION_QUESTION_IDS.MY_GO_TO_WORKOUT,
      COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_LOVE,
      COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_HATE,
    ],
  })

  return (
    <Section wrap="wrap">
      <SectionColumn>
        {coach.bodyImage?.url && (
          <SectionImage src={coach.bodyImage?.url} alt="Coach Body" />
        )}
      </SectionColumn>
      <SectionColumn>
        <SectionTitle
          subtitle={`About ${R.pathOr('', ['user', 'firstName'], coach)}`}
          title="Getting To Know Me"
        />
        <ul>
          {coachResponses.map(qr => {
            const Icon = Icons[qr.id]
            return (
              <li key={qr.id} className={s.li}>
                <div>
                  <Icon />
                </div>
                <div>
                  <BaseTypography
                    className={s.question}
                    tag="div"
                    weight="medium">
                    {qr.question}
                  </BaseTypography>
                  <BaseTypography tag="div" className={s.response}>
                    {qr.response}
                  </BaseTypography>
                </div>
              </li>
            )
          })}
        </ul>
      </SectionColumn>
    </Section>
  )
}

export default CoachGettingToKnowMeExperiment
