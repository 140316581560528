import * as R from 'ramda'
import cx from 'classnames'
import PaymentCard, {
  PaymentCardContent,
  PaymentCardContentSection,
  PaymentCardHeader,
} from '../../payment-card'
import Check from '../../payment-card/check'
import ArrowRight from '../../payment-info/arrow-right'
import Text from '../../../../../shared/text'
import s from './styles.module.css'

const PlanCard = ({
  product,
  price,
  title,
  dailyPrice,
  pricingDescription,
  oldPricingDescription,
  details = [],
  recommended,
  onSelection,
}) => {
  return (
    <div className={s.container}>
      <PaymentCard className={s.card}>
        {recommended && (
          <PaymentCardHeader className={s.paymentBanner}>
            <Text className={s.subTitle}>Most Popular</Text>
          </PaymentCardHeader>
        )}
        <PaymentCardHeader className={s.paymentHeader}>
          <Text className={s.subTitle}>{title}</Text>
        </PaymentCardHeader>
        <PaymentCardContent>
          <PaymentCardContentSection>
            <div className={s.threeADay}>
              <Text is="h1" className={s.three}>
                ${dailyPrice}
              </Text>
              <Text>/ day</Text>
            </div>
          </PaymentCardContentSection>
          <PaymentCardContentSection>
            <Text className={s.grayFont}>{pricingDescription}</Text>
            <Text className={s.strikeThrough}>{oldPricingDescription}</Text>
            <div>
              {R.addIndex(R.map)(
                (detail, idx) => (
                  <div
                    key={`detail-${idx}`}
                    className={s.listItem}
                    style={{ minHeight: detail.empty ? 48 : '' }}>
                    <div>{!detail.empty && <Check />}</div>
                    <Text is="p" className={cx(detail.bold ? s.bold : '')}>
                      {detail.text}
                    </Text>
                  </div>
                ),
                details
              )}
            </div>
            <button
              type="submit"
              className={cx(s.submitButton, { [s.recommended]: recommended })}
              onClick={() => onSelection(product, price)}>
              Select {title}
              <ArrowRight />
            </button>
          </PaymentCardContentSection>
        </PaymentCardContent>
      </PaymentCard>
    </div>
  )
}

export default PlanCard
