import { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import GoalsDropdown from '../goal-dropdown'
import StarRating from '../common/star-rating'
import AppStore from './app-store'
import GooglePlay from './google-play'
import { useClientReferral } from '../../../../providers/client-referral-provider'
import s from './styles.module.css'
import { HeroMedia } from './hero-media'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import { getTrialDaysDuration } from 'utilities/stripe'

const HeroExperiment = ({ fields, landingPageOptions }) => {
  const { isCoachPage } = useContext(CmsPageContext)
  const { clientFirstName } = useClientReferral()
  const trialDays = useClientTrialDays()

  const { header, subHeader, primaryImage, video } = fields

  const title = header
    .replace('{firstName}', clientFirstName)
    .replace(/^(.*?\s){4}/, '$&\n')
  const subTitle = subHeader
    .replace('{firstName}', clientFirstName)
    .replace('{period}', getTrialDaysDuration(trialDays))

  return (
    <section className={cx(s.container, { [s.banner]: isCoachPage })}>
      <HeroMedia
        imageFields={primaryImage?.fields}
        videoFields={video?.fields}
      />
      <div className={s.content}>
        <div className={s.leftContent}>
          <div className={s.titleContainer}>
            <h1 className={s.title}>{title}</h1>
            <p className={s.subTitle}>{subTitle}</p>
          </div>
          <GoalsDropdown
            analyticProps={{ location: 'Hero' }}
            landingPageOptions={landingPageOptions}
          />
        </div>
        <div className={s.mobileReviewsContainer}>
          <div>
            <AppStore />
            <StarRating rating={4.9} className={s.starRating} showRating />
          </div>
          <div>
            <GooglePlay />
            <StarRating rating={4.8} className={s.starRating} showRating />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroExperiment
