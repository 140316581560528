import * as R from 'ramda'
import StarRating from 'components/homepage/experiment/common/star-rating'
import { useState } from 'react'
import s from './styles.module.css'
import RightArrowGradient from './right-arrow-gradient'
import { Avatar } from 'components/design-system'
import { AnalyticsSection } from 'containers/analytics'
import { useAnalytics } from 'hooks/use-analytics'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const ViewAllReviews = ({ coach, limit, setLimit }) => {
  const logViewAll = useAnalytics('View All')

  const clickViewAll = () => {
    setLimit(coach.reviews.length)
    logViewAll('Click')
  }

  return (
    coach.reviews.length > limit && (
      <span className={s.viewMore} onClick={clickViewAll}>
        <span>View All</span> <RightArrowGradient />
      </span>
    )
  )
}

export default function CoachReviewsExperiment({ coach }: CoachProps) {
  const [limit, setLimit] = useState(3)

  const rating =
    coach.rating && coach.rating.score ? (coach.rating.score / 2).toFixed(1) : 0

  const total = coach.rating && coach.rating.total ? coach.rating.total : 0
  if (!total) return null

  return (
    <AnalyticsSection name="Reviews">
      <div id="coachReviews" className={s.container}>
        <div className={s.left}>
          <div className={s.ratingLabels}>
            <div>
              <span>{rating}</span>
              <span>out of 5</span>
            </div>
          </div>
          <StarRating
            className={s.starRating}
            color="#F4A916"
            rating={rating as number}
          />
          <div className={s.reviewsTotalLabels}>
            <span>{total} reviews</span>
            <span className={s.reviewsTotalSubLabel}>
              {' '}
              (from verified clients on Kickoff)
            </span>
          </div>
        </div>
        <div className={s.right}>
          {R.take(
            limit,
            R.map(review => {
              const authorRating = review.rating
                ? parseFloat((review.rating / 2).toFixed(1))
                : 0

              const author = `${review.client?.user?.firstName}${
                review.client?.user?.lastName
                  ? ` ${review.client.user.lastName.charAt(0)}`
                  : ''
              }`

              return (
                <div key={review.clientId}>
                  <StarRating
                    className={s.reviewStars}
                    rating={authorRating}
                    color="#F4A916"
                  />
                  <div className={s.reviewBody}>{review.review}</div>
                  <div className={s.reviewAuthorContainer}>
                    <Avatar
                      alt={`${review.client?.user?.firstName}-${review.client?.user?.lastName}`}
                      src={review.client?.profileImageFile?.url}
                      fallbackValue={`${review.client?.user?.firstName?.charAt(
                        0
                      )}${review.client?.user?.lastName?.charAt(0)}`}
                      size={48}
                    />
                    <div>
                      <div className={s.reviewAuthor}>{author}</div>
                      <div className={s.reviewDate}>
                        {D.format(new Date(review.createdAt), 'M/D/YY')}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }, coach.reviews)
          )}
          <ViewAllReviews coach={coach} limit={limit} setLimit={setLimit} />
        </div>
      </div>
    </AnalyticsSection>
  )
}
