import * as R from 'ramda'
import cx from 'classnames'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import TrainButtonExperiment from '../train-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'
const CoachFooterExperiment = ({ coach }: CoachProps) => {
  const targetAudience = useTargetAudience()
  const isGuestPass = targetAudience === Audience.GuestPass
  const isFreeTrial =
    targetAudience === Audience.FreeTrial ||
    targetAudience === Audience.CoachFreeTrial

  return (
    <div className={cx(s.footer)}>
      <div className={s.buttonWrapper}>
        <TrainButtonExperiment
          coach={coach}
          className={s.headerCTA}
          iconSize={18}>
          {isGuestPass
            ? 'Start Training for Free'
            : isFreeTrial
            ? `Start Free Trial with Coach ${R.pathOr(
                '',
                ['user', 'firstName'],
                coach
              )}`
            : `Train with Coach ${R.pathOr('', ['user', 'firstName'], coach)}`}
        </TrainButtonExperiment>
      </div>
    </div>
  )
}

export default CoachFooterExperiment
