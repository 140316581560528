export const NAV_LINKS = {
  howItWorks: 'how-it-works',
  successStories: 'success-stories',
  pricing: 'pricing',
  trainers: 'trainers',
  blog: 'blog',
}

export const NAVIGATION = [
  {
    label: 'How It Works',
    href: `#${NAV_LINKS.howItWorks}`,
    current: false,
  },
  {
    label: 'Success Stories',
    href: `#${NAV_LINKS.successStories}`,
    current: false,
  },
  {
    label: 'Pricing',
    href: `#${NAV_LINKS.pricing}`,
    current: false,
  },
  {
    label: 'Become a Coach',
    href: `/coachwithkickoff`,
    current: false,
    ref: 'client_landing_page',
  },
  {
    label: 'Gifts',
    href: 'https://trainwithkickoff-1.hubspotpagebuilder.com/remote-personal-training-gift-subscriptions',
    current: false,
  },
]

export const COACH_EXPERIMENT_NAV = [
  {
    label: 'How It Works',
    href: `#${NAV_LINKS.howItWorks}`,
    current: false,
  },
  {
    label: 'Reviews',
    href: `#coachReviews`,
    current: false,
  },
  {
    label: 'Pricing',
    href: `#${NAV_LINKS.pricing}`,
    current: false,
  },
  {
    label: 'Become a Coach',
    href: `/coachwithkickoff`,
    current: false,
    ref: 'client_landing_page',
  },
  {
    label: 'Gifts',
    href: 'https://trainwithkickoff-1.hubspotpagebuilder.com/remote-personal-training-gift-subscriptions',
    current: false,
  },
  {
    label: 'Blog',
    href: `/${NAV_LINKS.blog}`,
    current: false,
  },
]
