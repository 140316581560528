import s from './styles.module.css'
import CoachMissionExperiment from './coach-mission'
import CoachQuoteExperiment from './coach-quote'
import CoachWhyTrainWithMeExperiment from './coach-why-train-with-me'
import CoachGettingToKnowMeExperiment from './coach-getting-to-know-me'
import CoachALittleMoreAboutMeExperiment from './coach-a-little-more-about-me'
import CoachReviewsExperiment from './coach-reviews'
import CoachHeaderExperiment from './coach-header'
import CoachFooterExperiment from './coach-footer'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachProfileExperimentProps extends CoachProps {
  onCtaClick?: () => void
}

const CoachProfileExperiment = ({
  coach,
  onCtaClick,
}: CoachProfileExperimentProps) => {
  return (
    <div className={s.container}>
      <CoachHeaderExperiment coach={coach} />
      <CoachMissionExperiment coach={coach} />
      <CoachQuoteExperiment coach={coach} />
      <CoachWhyTrainWithMeExperiment coach={coach} />
      <CoachGettingToKnowMeExperiment coach={coach} />
      <CoachALittleMoreAboutMeExperiment coach={coach} />
      <CoachReviewsExperiment coach={coach} />
      <CoachFooterExperiment coach={coach} />
    </div>
  )
}

export default CoachProfileExperiment
