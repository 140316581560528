import cx from 'classnames'
import s from './styles.module.css'

interface IPaymentCardProps {
  children: React.ReactNode
  className?: string
}

export const PaymentCardHeader = ({
  children,
  className,
}: IPaymentCardProps) => (
  <div className={cx(s.header, className)}>{children}</div>
)

export const PaymentCardContent = ({
  children,
  className,
}: IPaymentCardProps) => (
  <div className={cx(s.content, className)}>{children}</div>
)

export const PaymentCardContentSection = ({ children }: IPaymentCardProps) => (
  <div className={s.contentSection}>{children}</div>
)

const PaymentCard = ({ children, className }: IPaymentCardProps) => {
  return <div className={cx(s.container, className)}>{children}</div>
}

export default PaymentCard
