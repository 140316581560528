import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import * as R from 'ramda'
import { getLiveUpgradePrice } from 'utilities/stripe'
import PaymentCard, {
  PaymentCardContent,
  PaymentCardHeader,
} from '../payment-card'
import s from './styles.module.css'
import { billingPeriodInWords } from 'components/payment/helpers'

const billingMonthAbbreviation = (num: number) => {
  if (num === 1) return 'mo'
  if (num === 6) return '6 mo'
  if (num === 12) return 'yr'
  return ''
}

const priceDisplay = (price: number) => {
  return price % 1 === 0 ? price : price.toFixed(2)
}

interface ILiveWorkoutSelectionProps {
  products: any[]
  product: any
  coupon: any
  billingPeriodInMonths: number
  onSelection: (product: any, price: number) => void
}

const LiveWorkoutSelection = ({
  products,
  product,
  coupon,
  billingPeriodInMonths,
  onSelection,
}: ILiveWorkoutSelectionProps) => {
  const liveUpgradePrice = getLiveUpgradePrice({
    coupon,
    products,
    billingPeriodInMonths,
    sessions: 1,
  })

  const livePlusUpgradePrice = getLiveUpgradePrice({
    coupon,
    products,
    billingPeriodInMonths,
    sessions: 2,
  })

  const options = useMemo(
    () => [
      {
        label: 'No Live Sessions',
        description: '',
        product: products[0],
        price: `+ $0`,
      },
      {
        label: '1 Weekly Live Session',
        description: `$${liveUpgradePrice} paid ${
          billingPeriodInMonths === 6 ? 'every ' : ''
        }${billingPeriodInWords(billingPeriodInMonths)} with 4 sessions total`,
        product: products[1],
        price: `+ $${liveUpgradePrice}/${billingMonthAbbreviation(
          billingPeriodInMonths
        )} ($${priceDisplay(
          liveUpgradePrice / (4 * billingPeriodInMonths)
        )}/session)`,
      },
      {
        label: '2 Weekly Live Sessions',
        description: `$${livePlusUpgradePrice} paid  ${
          billingPeriodInMonths === 6 ? 'every ' : ''
        }${billingPeriodInWords(billingPeriodInMonths)} with 8 sessions total`,
        product: products[2],
        price: `+ $${livePlusUpgradePrice}/${billingMonthAbbreviation(
          billingPeriodInMonths
        )} ($${priceDisplay(
          livePlusUpgradePrice / (8 * billingPeriodInMonths)
        )}/session)`,
      },
    ],
    [billingPeriodInMonths, livePlusUpgradePrice, liveUpgradePrice, products]
  )

  const [liveSelection, setLiveSelection] = useState(0)

  const handleLiveSelection = (idx: number) => {
    setLiveSelection(idx)
    const price = R.find(
      R.propEq('billingPeriodInMonths', billingPeriodInMonths),
      options[idx].product.prices
    )
    onSelection(options[idx].product, price)
  }

  useEffect(() => {
    setLiveSelection(
      R.findIndex(R.pathEq(['product', 'id'], product.id), options)
    )
  }, [options, product])

  return (
    <PaymentCard>
      <PaymentCardHeader>
        <h2 className={s.title}>Add Weekly Live Workouts</h2>
      </PaymentCardHeader>
      <PaymentCardContent>
        <p className={s.p}>
          Get to your goals with live workouts at 80% off competitors.
        </p>
        <div className={s.selections}>
          {options.map((option: any, index: number) => (
            <div key={`live-selection-${index}`} className={s.item}>
              <button
                className={cx(s.button, {
                  [s.selected]: index === liveSelection,
                })}
                onClick={() => handleLiveSelection(index)}>
                <div>
                  <span>{option.label}</span>
                  {/* <span className={s.description}>{option.description}</span> */}
                </div>
                <span>{option.price}</span>
              </button>
            </div>
          ))}
        </div>
      </PaymentCardContent>
    </PaymentCard>
  )
}

export default LiveWorkoutSelection
