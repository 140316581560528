import cx from 'classnames'
import * as R from 'ramda'
import { useEffect, useState } from 'react'
import { Button } from 'components/design-system/button'
import s from './styles.module.css'

const BILLING_PERIODS = [
  {
    text: 'Monthly',
    value: 1,
  },
  {
    text: '6 Months',
    subtext: 'Save 10%',
    value: 6,
  },
  {
    text: 'Yearly',
    subtext: 'Save 20%',
    value: 12,
  },
]

const PlanDurationSelection = ({ duration = 0, onChange }) => {
  const [selected, setSelected] = useState(duration)

  const handleClick = index => {
    // setSelected(index)
    onChange(BILLING_PERIODS[index].value)
  }

  useEffect(() => {
    setSelected(BILLING_PERIODS.findIndex(({ value }) => value === duration))
  }, [duration])

  return (
    <div className={s.container}>
      <div className={s.label}>Plan Duration:</div>
      <div className={s.options}>
        {R.addIndex(R.map)((period, index) => {
          return (
            <div key={`plan-${index}`} className={s.option}>
              <Button
                variant="primary"
                size="small"
                className={cx(s.months, index === selected ? s.white : s.black)}
                onClick={() => handleClick(index)}>
                {period.text}
              </Button>
              <div className={s.subtext}>{period.subtext}</div>
            </div>
          )
        }, BILLING_PERIODS)}
      </div>
    </div>
  )
}

export default PlanDurationSelection
