const Apple = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.506 7.03894C17.0269 5.08172 14.2369 6.49063 11.8768 6.49063C9.51673 6.49063 6.72387 5.08172 4.24758 7.03894C1.97237 8.83358 1.21729 14.2788 4.28608 19.0888C7.23082 23.7063 10.1998 22.1412 11.8796 22.1412C13.5595 22.1412 16.5285 23.7098 19.4732 19.0888C22.5391 14.2788 21.7876 8.83358 19.506 7.03894Z"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15 0V1.5C15 2.29565 14.6839 3.05871 14.1213 3.62132C13.5587 4.18393 12.7956 4.5 12 4.5V3C12 2.20435 12.3161 1.44129 12.8787 0.87868C13.4413 0.316071 14.2044 0 15 0V0Z"
        fill="#212121"
      />
    </svg>
  )
}

export default Apple
