import cx from 'classnames'
import ArrowRight from './arrow-right'
import { Button } from 'components/design-system/button'
import s from './styles.module.css'

import { useExperiment } from 'utilities/experiment/context'
import { TrackedA } from 'components/shared/analytics'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface TrainButtonExperimentProps extends CoachProps {
  children: React.ReactNode
  className?: string
  iconSize?: number
  name?: string
}
const TrainButtonExperiment = ({
  children,
  className,
  iconSize,
  name = 'CTA',
  coach,
}: TrainButtonExperimentProps) => {
  const clp = useExperiment('clp')

  return (
    <TrackedA
      name={name}
      className={s.link}
      href={
        clp.split && clp.split === 'B'
          ? `${coach.user.username}/sign-up`
          : '#signUp'
      }>
      <Button
        variant="primary"
        size="small"
        className={cx(s.button, className)}
        iconRight={<ArrowRight size={iconSize || 24} />}>
        {children}
      </Button>
    </TrackedA>
  )
}

export default TrainButtonExperiment
